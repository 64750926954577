@import "../../../../styles/variables.scss";

.noData {
    width: 100%;
    background-color: $white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 141px 0 168px 0;
    &--title {
        font-size: 30px;
        font-weight: 700;
        line-height: 60px;
        color: $text;
        font-family: $font-family-getlife-primary;
        padding-bottom: 37px;
    }

    .button-primary {
        width: 227px;
    }
}