@import "./../../../styles/variables.scss";

.form--checkboxContainer {
	display: flex;
	flex-direction: row;
}

.form--labelCheckbox {
	display: inline-block;
	color: $label;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.5;
}

.form--checkbox {
	display: inline-block;
	margin-right: 13px;
	transform: scale(1.263);
	border: solid 1px $border;
}
.customCheckBox-containerGrey {
	color: $text;
}
