.ReviewCards {
  overflow: hidden;
  background-color: var(--theme-quaternary);
  &-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 24px;
    padding-right: 24px;
    gap: 32px;
    @media (min-width: 768px) {
      gap: 64px;
    }
    @media (min-width: 1280px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  &-labels {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    color: #424242;
    h2 {
      font-family: "DM Sans";
      font-weight: 700;
      span {
        font-family: "Merriweather";
        font-weight: 300;
      }
    }
    @media (min-width: 768px) {
      gap: 16px;
    }
  }
  &-cards {
    display: flex;
    flex-direction: row;
    gap: 32px;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-card {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 240px;
    min-width: 240px;
    min-height: 415px;
    @media (min-width: 768px) {
      flex: 352px;
      min-width: 352px;
    }
  }
}
