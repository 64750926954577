@import "../ButtonBase/Button.styles.scss";

.icon-button {
    display: flex;
    justify-content: space-between;
    &:hover {
        path {
            fill: $primary;
            transition: 0.3s;
            box-shadow: none;
        }
    }
}

.reverse {
    flex-direction: row-reverse;
}
