@import './../../../../styles/variables.scss';

.modalSendEmail {
    width: 400px;
}

.modalSendEmail .header {
    border-bottom: 1px solid $text;
    padding: 20px 25px 20px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modalSendEmail .closeIcon {
    background-color: transparent;
    color: $primary;
    cursor: pointer;
}

.modalSendEmail .tittle {
    display: flex;
}

.modalSendEmail .shareIcon {
    background-color: #EEEEEE;
    border-radius: 20px;
    padding: 5px;
    font-size: 30px;
}

.modalSendEmail .tittle h2 {
    font-family: $font-family-getlife;
    font-weight: 700;
    font-size: 20px;
    color: $primary;
    margin-right: 10px;
}

.modalSendEmail .close-box {
    display: flex;
}

.box-info-send-otp {
    width: 100%;
    border-radius: 20px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 25px 25px 0px 25px;
}

.box-info-send-otp .inputGetLife-container {
    width: 100%;
}

.box-info-send-otp .inputGetLife-container input {
    font-family: $font-family-getlife !important;
}

.box-info-send-otp .inputGetLife-container label {
    font-family: $font-family-getlife !important;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: $primary;
    margin-top: 10px;
    margin-bottom: 5px;
}

.box-info-send-otp .textArea-textAreaContainer > p {
    color: $text;
    margin-bottom: 5px;
}

.box-info-send-otp .textArea-textAreaContainer > textarea {
    font-family: $font-family-getlife !important;
}

.button-container-modal-send {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 25px;
}

.button-container-modal-send .button-secondary {
    margin-right: 10px;
    background-color: $white;
    color: $primary;
}

.email-box h5, .sms-box h5 {
    color: $primary;
    font-family: $font-family-getlife !important;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
}

.email-box p, .sms-box p {
    color: $text;
    font-family: $font-family-getlife !important;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
}