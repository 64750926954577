@import "../../../../../styles/variables.scss";

.form-nationality-container {
    display: flex;
}

.form-nationality-select {
    width: 93%;
    margin-right: 10px;
}

.form-nationality-simple {
    width: 100%;
}

.form-nationality-icon {
    align-self: center;
    margin-top: 15px;
}
