@import '../../../styles/variables.scss';

.productCard {
    width: 576px;
    background-color: $white;
    box-shadow: 0px 4px 30px $shadow;
    border-radius: 8px;
    &--header {
        height: 74px;
        background-color: $bg-card;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &__title {
            font-size: 12px;
            line-height: 20px;
            color: $primary;
            font-weight: 400;
            padding-left: 26px;
        }
        &__subtitle {
            color: $primary;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            padding-left: 26px;
        }
        &__company {
            width: 158px;
            height: 30px;
            background-color: $white;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            color: $border-hover;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 32px;
        }
    }
    &--main {
        padding-left: 26px;
        margin-top: 15px;
        &__delete {
            font-weight: bold;
        }
        &__price {
            font-size: 20px;
            font-weight: 400;
            line-height: 20px;
            color: $action-color;
            padding: 20px 0 30px 0;
        }
        &__price .periodSelect {
            font-weight: 700;
            text-decoration: underline;
        }
        &__price .periodSelectElection {
            font-weight: 700;
        }
        &__div {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-bottom: 22px;
        }
        &__div .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
            background-color: $primary;
            color: $white;
            outline: 4px solid $placeholder;
            border-radius: 100%;
        }
        &__details {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color: $primary;
            padding-left: 8px;
        }
    }
    &--footer {
        border-top: 1px solid $bg-web;
        height: 72px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 26px 0 26px;
        &__button .button-secondary {
            background-color: $white;
            font-size: 16px;
            font-weight: 700;
            color: $text;
            width: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__anchor {
            font-weight: 700;
            color: $text;
            font-size: 16px;
            line-height: 19.5;
        }
    }

    &__infoBox{
        border-radius: 0px !important;
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        margin-top: 15px !important;
        height: 59px;
        padding: 14px 30px 16px !important;
    }
}

@media ( max-width: 768px ) {
    .productCard {
        width: 100%;

        &--footer {
            flex-wrap: wrap;
            height: auto;

            &__button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}