.downloadableFilesModalBody {
    max-width: 45ch;
    &__text {
        margin-bottom: 8px;
    }
}

.downloadableFilesModalTitle {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
