@import "../../../../styles/variables.scss";

.postStackForm-mainContainer {
    background-color: $white;
    border-radius: 8px;
    border: solid 0.5px $input-hover;
    padding: 20px;
}

.postStackForm-mainContainerAnswered {
    // background-color: $bg-web;
    border-radius: 8px;
    border: solid 0.5px $input-hover;
    padding: 20px;
}

.postStackForm-postStackForm {
    color: $label;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    width: 55%;
    padding: 20px 0px 0px 15px;
}
.postStackForm-button {
    width: 82px;
}
.postStackForm-headerForm {
    display: flex;
}
.postStackForm-form {
    margin-top: 0px !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}

.postStackForm-formBeneficiaries {
    margin-top: 0px !important;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}
.postStackForm-addButtonContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.postStackForm-titleHeader {
    width: 100%;
}

.dateInput-broker {
    padding: 0 !important;
    border-color: green !important;
    align-items: flex-start !important;
}

.dateInput-broker > div > div {
    background-color: #FFFFFF !important;
    padding: 10px 18px !important;
}

.dateInput-broker > p {
    font-family: "DM sans", sans-serif;
    color: #2c2302;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.54;
    margin-bottom: 10px;
    text-align: left;
    padding: 0 !important;
}