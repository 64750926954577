@import './../../../../styles/variables.scss';

.button-secondary {
  font-size: 14px;
  font-family: $font-family-getlife;
  width: 134px;
  height: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: $primary-shadow;
}

.button-secondary:hover {
  background-color: $primary-shadow-hover;
  transition: 0.3s;
}

.button-secondary-downloadPdf {
  background-color: $bg-web;
  font-size: 14px;
  font-family: $font-family-getlife;
  width: 134px;
  height: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .button-secondary {
    font-size: 12px;
    width: 128px;
  }

  .button-secondary-downloadPdf {
    font-size: 12px;
    width: 128px;
  }
}
@media only screen and (max-width: 414px) {
  .button-secondary {
    font-size: 12px;
    width: 108px;
    height: 35px;
  }

  .button-secondary-downloadPdf {
    font-size: 12px;
    width: 108px;
    height: 35px;
  }
}
