.cardsBroker {
  &-cardsInfo {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  &-modalTitle {
    display: flex;
    flex-direction: row;
    gap: 4px;
    font-weight: 700;
    color: #424242;
    svg {
      width: 28px;
      height: 28px;
    }
  }
  &-doubtModalBody {
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: #424242;
    font-weight: 400;
  }
  &-doubtModalCTA {
    margin-left: auto;
  }
  &-shareModalBody {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #424242;
    font-weight: 400;
  }
  &-shareModalMedias {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  &-copyClipboardWrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    button {
      height: fit-content;
    }
  }
  &-clipboardInput {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border: 1px solid #ececec;
    height: 42px;
    padding: 0px 8px;
    border-radius: 8px;
    input {
      flex: 1;
      padding: 0;
      margin: 0;
      border: unset;
    }
  }
}
