.LandingBanner {
  background-color: #ffffff;
  &-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-right: 24px;
    padding-left: 24px;
    @media (min-width: 1280px) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  &-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px 0;
    @media (min-width: 1024px) {
      flex-direction: row;
      padding: 80px 0;
      gap: 62px;
    }
    &:last-child:not(:first-child) {
      padding-top: 40px;
      flex-direction: column-reverse;
      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }
  }
  &-banner {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-width: 225px;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }
  &-content {
    color: #424242;
    width: 100%;
    max-width: 580px;
    & > * + * {
      margin-top: 24px;
    }
  }
  &-subtitle {
    color: var(--theme-primary);
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-bottom: 8px;
  }
  &-title {
    font-family: "DM Sans";
    font-weight: 700;
    margin-bottom: 24px;
  }
  &-list {
    li {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    & > * + * {
      margin-top: 16px;
    }
  }
}
