@import "../../../../../styles/variables.scss";

.form--checkboxContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 50px;
    cursor: pointer;
    &:hover {
        background-color: $primary-hover;

        label {
            color: $primary;
        }
    }

    .checkmark{
        position: relative;
        top: 2px;
        left: 0;
        height: 25px;
        width: 25px;
        border: 3px solid $primary-hover;
        border-radius: 5px;
        &::after{
            position: absolute;
            left: 5px;
            bottom: 4px;
            width: 4px;
            height: 10px;
            border: solid $primary-hover;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
.form--labelCheckbox {
    display: flex;
    align-self: center;
    color: $text;
    font-size: $font-m;
    margin-bottom: 0;
    margin-right: 10px;
    font-weight: 400;
    cursor: pointer;
}

.form--checkbox {
    display: flex;
    align-self: center;
    margin-right: $font-m;
    cursor: pointer;
}


.checked {
    color: $primary;
}

.checkedContainer {
    background-color: $new-secondary !important;
}
