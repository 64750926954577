@import "./../../../styles/variables.scss";

.loginPage {
	background-color: $bg-web;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loginPage--main {
	width: 396px;
	height: 417px;
	background-color: $white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 8px 8px 0 0;
	border-bottom: solid 1px $border;
}

.loginPage--main--otp {
	width: 396px;
	height: 345px;
	background-color: $white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 8px 8px 0 0;
	border-bottom: solid 1px $border;
}

.loginPage--main__img {
	width: 120px;
	margin: 35px 0 25px 0;
}

.otp--form__input {
	margin-bottom: 0 !important;
}

.loginPage--main__title {
	font-family: $font-family-getlife;
	font-size: 12px;
	margin: 0 0 36px 0;
	text-align: center;
}

.loginPage--main__title_error {
	color: $error;
	font-family: $font-family-getlife;
	font-size: 12px;
	margin: 0 0 36px 0;
	text-align: center;
}

.loginPage--main__form {
	display: flex;
	flex-direction: column;
	width: 275px;
}

.form--btn {
	margin: 16px 0 47px 0 !important;
	height: 48px !important;
	max-height: 48px !important;
}

.makeStyles-button-5 {
	color: $border !important;
}

.MuiButton-label {
	text-transform: capitalize;
	font-family: $font-family-getlife;
	font-size: 12px;
	font-weight: bold;
	color: $border;
}

.loginPage--footer {
	width: 396px;
	height: 86px;
	background-color: $white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 0 0 8px 8px;
}

.loginPage--footer__title {
	color: $primary;
	font-family: $font-family-getlife;
	font-size: 12px;
	margin-left: 5px;
	text-decoration: none;
	border: 1px solid $primary;
	background: none;
	cursor: pointer;
	max-width: 300px;
}

.container-loading {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
