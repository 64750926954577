@import "./../../../styles/variables.scss";

body {
  font-family: $font-family-getlife;
}

.header {
  display: flex;
  min-height: 80px;
  background-color: #fff;
  box-shadow: 0px 3px 4px 0px #00000012;

  box-shadow: 0px 3px 3px 0px #0000000F;
  
  box-shadow: 0px 1px 8px 0px #0000001A;
  position: relative;
  z-index: 10;
  
  &-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 8px 64px;
    gap: 20px;
    margin: auto 0;
  }
  &-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
  &-divider {
    width: 2px;
    height: 40px;
    border-radius: 100%;
    background-color: #d9d9d9;
  }
  &-brokerLogo,
  &-life5Logo {
    height: 40px;
  }
  &-brokerName {
    margin: auto 0;
    font-weight: 500;
    color: #424242;
    max-width: 100px;
  }
  &-navbar {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex: 1;
    overflow: auto;
    height: 50px;
    align-items: center;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-link {
    font-size: 16px;
    color: #424242;
    font-weight: 500;
    text-decoration: unset;
    padding: 8px 16px;
    border-radius: 999px;
    white-space: nowrap;
    &:hover {
      background-color: #f5f7fa;
    }
  }
  &-activeLink {
    text-decoration: underline;
  }
  &-infoLink {
    font-weight: 500;
    color: var(--primary-color, #3430e3);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-decoration: unset;
    padding: 8px 16px;
    border-radius: 999px;
    &:hover {
      background-color: #ecf0ff;
    }
  }
  &-ctas {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  &-modalTitle {
    display: flex;
    flex-direction: row;
    gap: 4px;
    color: #424242;
    font-weight: 700;
    svg {
      height: 28px;
      width: 28px;
    }
  }
  &-modalContent {
    color: #424242;
    font-weight: 400;
  }
  &-modalCTA {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  &-helpButton {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
