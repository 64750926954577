@import "../../../../styles/variables.scss";

.card-container {
    background-color: $bg-card;
    padding: 20px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 200px;
    border-radius: 8px;
    box-shadow: 4px 0 10px 0 $shadow;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
}

.card-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    font-size: 13px;
    padding-bottom: 20px;
    width: 100%;
    svg {
        margin-right: 10px;
    }
}

.card-body {
    font-size: 13px;
    font-weight: 400;
}
