@import '../../../src/styles/variables.scss';

.waitingSignature-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    text-align: center;
    color: $primary;
    padding: 0 50px;

    h1 {
        font-size: 38px;
        font-weight: 400;
    }

    h2 {
        font-size: 18px;
        font-weight: 400;
        max-width: 600px;
        padding-top: 30px;
    }

    img {
        padding: 70px 0 25px;
    }

    .waitingSignature-buttonContainer {
        padding: 32px 0 90px;

        button {
            padding: 0 30px;
        }
    }

    .waitingSignature-footerContainer {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
        max-width: 650px;

        .waitingSignature-footer-secure {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 10px;

            img {
                padding: 0 10px;
            }
        }

        .waitingSignature-footer {
            padding-top: 60px;
            font-size: 10px;
            font-weight: 700;
            line-height: 16px;
            text-align: center;
            color: #C4C4C4;
            padding-bottom: 60px;
            width: 100%;
        }
    }    
    
    h2 > a {
        color: $primary;
    }
}