@import './../../../styles/variables.scss';

.autocomplete-container {
	display: flex;
	flex-direction: column;
}
.autocomplete-container > label {
	font-size: 14px;
	padding-bottom: 10px;
	font-family: $font-family-getlife !important;
	font-weight: 500;
}

.autocomplete-container > input:focus {
	border: solid 2px $primary !important;
	outline: none;
	box-shadow: 1px 0px 1px 2px $primary-shadow;
}

.location-search-input:hover {
	border: solid 2px $border-hover;
}
.location-search-input {
	font-family: $font-family-getlife !important;
	font-weight: 400;
	height: 48px;
	border-radius: 8px;
	border: solid 2px $border;
	font-size: 1rem;
	padding-left: 10px;
	transition: border 300ms;
	transition: box-shadow 300ms;
	width: auto !important;
}

.autocomplete-dropdown-container {
	margin-top: 2px;
	border-color: $border;
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
}

.autocomplete-dropdown-container:empty {
	border: none;
}

.autocomplete-dropdown-container * {
	font-family: $font-family-getlife !important;
	color: $label;
	width: calc(100% - 32px);
	padding: 10px 0px;
	border-radius: 8px;
	margin: 16px;
}

.location-search-input > *:hover {
	cursor: 'pointer';
	background-color: $input-hover;
}
