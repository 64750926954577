@import "../../../../../styles/variables.scss";

.beneficiaries--other {
    margin-top: 20px;
}

.otherBeneficiaries--form {
    margin-top: 0px !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}

.beneficiaries--buttons-other {
    display: flex;
    column-gap: 10px;
    padding: 20px 10px 10px 10px;
    max-width: 600px;
}
.beneficiaries--text-other {
    padding: 10px;
    width: max-content;
    p {
        margin-top: 10px;
    }
}
.beneficiaries--cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    width: max-content;
}

.beneficiaries--card {
    background-color: $bg-web;
    max-width: 423px;
    max-height: 150px;
}

.beneficiaries--card-title {
    justify-content: space-between;
}

.physicalPerson {
    color: $primary;
}
.legalPerson {
    color: $label;
}
.bankingEntity {
    color: #148f63;
}

.beneficiaries--card-body {
    display: flex;
    flex-direction: column;
    width: inherit;
}

.beneficiaries--card-actions {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    button {
        max-width: 25%;
        justify-content: flex-end;
    }
}
.beneficiaries--modal-buttons {
    display: flex;
    column-gap: 10px;
}

@media only screen and (max-width: 1700px) {
    .beneficiaries--cards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 1300px) {
    .beneficiaries--cards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 960px) {
    .beneficiaries--cards {
        grid-template-columns: repeat(1, 1fr);
    }
}
