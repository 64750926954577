.loading {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10000;
}
.loading-wheel {
    animation: spin 1s linear infinite;
    border-radius: 50%;
    border-width: 30px;
    height: 20px;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    position: absolute;
    top: 50%;
    width: 20px;
}
.style_loader .loading-wheel {
    border-color: #ccc transparent;
    border-style: double;
}
@keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-360deg);
    }
}
