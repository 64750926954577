@import "../../../../styles/variables.scss";

.companyInfo {
  &-grid {
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    padding: 40px 32px 32px 32px;
    box-sizing: content-box;
    @media (min-width: 1024px) {
      grid-template-columns: 1fr 304px;
    }
  }
  &-dragOver {
    background-color: #e6f3f0;
    border-color: #018565;
  }
  &-themeSelector {
    & + .selectDS-icon {
      color: var(--iconColor, var(--primary-color));
    }
  }
}
