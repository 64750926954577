@import "./../../../../styles/variables.scss";

.productosDisponibles {
    padding: 30px 70px;
}

.productosDisponibles h1 {
    padding-bottom: 30px;
    font-size: $font-secondTitle;
    font-weight: bold;
}

.productosDisponibles .subtitle {
    max-width: 690px;
    padding-bottom: 46px;
}

.productosDisponibles .cards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
}

.productosDisponibles .cards .card {
    max-width: 474px;
    width: 100%;
    margin-bottom: 20px;
    background-color: $white;
    border-radius: 8px;
}

.productosDisponibles .upsell-personalization-second-row {
    flex-direction: column;
    align-items: center;
}

.productosDisponibles
    .upsell-personalization-second-row
    .upsell-personalization-second-row-left
    .upsell-personalization-custom-capital-title,
.productosDisponibles
    .upsell-personalization-second-row
    .upsell-personalization-second-row-left
    .upsell-personalization-custom-capital-input-container {
    margin-left: 10px;
    margin-right: 10px;
}

.productosDisponibles
    .upsell-personalization-second-row
    .upsell-personalization-second-row-left
    .upsell-personalization-custom-capital-title {
    font-size: 16px;
}

.productosDisponibles
    .upsell-personalization-second-row
    .upsell-personalization-second-row-left
    .upsell-personalization-custom-capital-input-container
    .inputGetLife-input {
    margin-bottom: 0;
}

.productosDisponibles
    .upsell-personalization-second-row
    .upsell-personalization-second-row-right
    .upsell-personalization-custom-capital-ipa-container {
    flex-direction: row;
    padding-right: 10px;
    padding-left: 10px;
}

/* OPTIONAL */
.productosDisponibles .upsell-personalization-custom-capital-addon-container {
    margin: 0 auto;
}

/* PERSONALIZATION */
.productosDisponibles .upsell-personalization-second-row .upsell-personalization-second-row-left,
.productosDisponibles .upsell-personalization-second-row .upsell-personalization-second-row-right {
    margin-right: 0;
    width: 100%;
}

@media (max-width: 768px) {
    .productosDisponibles .cards {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .productosDisponibles .cards .card {
        max-width: 100%;
    }

    .productosDisponibles .upsell-inner-personalization {
        width: 100%;
    }

    .productosDisponibles .upsell-personalization-second-row .upsell-personalization-second-row-left {
        text-align: center;
    }

    .productosDisponibles
        .upsell-personalization-second-row
        .upsell-personalization-second-row-left
        .upsell-personalization-custom-capital-input-container {
        display: flex;
        flex-direction: column;
    }

    .productosDisponibles
        .upsell-personalization-second-row
        .upsell-personalization-second-row-left
        .upsell-personalization-custom-capital-input-container
        .inputGetLife-container {
        margin-bottom: 20px;
        width: 100%;
    }

    .productosDisponibles
        .upsell-personalization-second-row
        .upsell-personalization-second-row-left
        .upsell-personalization-custom-capital-input-container
        .upsell-personalization-add-button,
    .productosDisponibles
        .upsell-personalization-second-row
        .upsell-personalization-second-row-right
        .upsell-personalization-custom-capital-ipa-container
        .upsell-personalization-add-button {
        width: 100%;
        margin-right: 10px;
        margin-left: 10px;
    }

    .productosDisponibles
        .upsell-personalization-second-row
        .upsell-personalization-second-row-right
        .upsell-personalization-custom-capital-ipa-container {
        flex-direction: column;
    }
}
