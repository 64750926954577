@import '../../../styles/variables.scss';

.welcomeTypeform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 72px);
    background-color: $bg-web;
    &--title {
        font-family: $font-family-getlife;
        font-weight: 700;
        font-size: 40px;
        line-height: 49px;
        color: $primary;
        margin-bottom: 19px;
        text-align: center;
    }
    &--text {
        font-family: $font-family-getlife;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: $primary;
        width: 430px;
        margin-bottom: 33px;
        text-align: center;
    }
    &--buttons {
        width: 364px;
    }
    &--buttons .button-primary {
        width: 100%;
        margin-bottom: 19px;
    }
}

.typeform {
    all: unset;
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #0445af;
    color: #ffffff;
    font-size: 20px;
    border-radius: 25px;
    padding: 0 33px;
    font-weight: bold;
    height: 50px;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    margin: 0;
    text-decoration: none;
}

@media (max-width: 440px) {
    .welcomeTypeform {
        &--title {
            text-align: center;
        }
        &--text {
            width: 90%;
        }
        &--buttons {
            width: 90%;
        }
    }
}
