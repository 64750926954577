@import "../../../../../styles/variables.scss";

.couponModal {
    &__body{
        &--input{
            margin-bottom: 5px !important;
        }
        &--message{
            column-gap: 5px;
            color: $text !important;
        }
    }
}