@import "../../../styles/variables.scss";

.paymentMethod {
    width: 100%;
    min-width: 610px;
    &--title {
        width: 100%;
        font-size: 12px;
        color: $text;
        background-color: $bg-header;
        line-height: 20px;
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 13px;
        border-radius: 8px;
        margin-top: 8px;
    }
    &--section {
        background-color: $white;
        width: 100%;
        border-radius: 12px;
        &Select {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 15px;
            padding-bottom: 15px;
            flex-wrap: wrap;
            grid-row-gap: 20px;
            &__title {
                color: $primary;
                font-size: 16px;
                font-weight: 700;
                padding-bottom: 13px;
            }
            &__information {
                font-size: 13px;
                font-weight: 700;
                color: $text;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .text {
            font-size: 16px;
            line-height: 24px;
            color: $text;
            font-weight: 400;
            padding-bottom: 20px;
        }
        .bold1 {
            font-weight: 600;
            padding-bottom: 0;
        }
        .bold2 {
            font-weight: 600;
            padding-bottom: 20px;
        }
        .bold {
            font-weight: 600;
            padding-bottom: 12px;
        }
    }
}
