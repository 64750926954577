.LandingFaq {
  background-color: #ffffff;
  padding-top: 64px;
  padding-bottom: 64px;
  &-wrapper {
    border-top: var(--theme-primary) 5px solid;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
    @media (min-width: 1280px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
