@import './../../../../styles/variables.scss';

.inputGetLife-container {
	display: flex;
	flex-direction: column;
}

.inputGetLife-container > label {
	font-family: $font-family-getlife;
	color: $label;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.54;
	margin-bottom: 15px;
	text-align: left;
}

.inputGetLife-container > input::placeholder {
	color: hsl(0, 0%, 80%);
}

.inputGetLife-container > input:focus {
	border: solid 2px $primary !important;
	outline: none;
	box-shadow: 1px 0px 1px 2px $primary-shadow;
}

*:not(.newDashboard-grid) + .inputGetLife-container > input {
	margin-bottom: 20px;
}

.inputGetLife-input:hover {
	border: solid 2px $border-hover;
}
.inputGetLife-input {
	font-family: $font-family-getlife;
	font-weight: 400;
	height: 48px;
	border-radius: 8px;
	border: solid 2px $border;
	font-size: 1rem;
	padding-left: 10px;
	transition: border 300ms;
	transition: box-shadow 300ms;
	width: 100%;
	&:disabled:hover {
		border: solid 2px $border;
	}
}

.inputGetLife-errorMessageContainer {
	padding-bottom: 20px;
}

.inputGetLife-input-error {
	font-family: $font-family-getlife;
	font-weight: 400;
	height: 48px;
	border-radius: 8px;
	border: solid 2px $error;
	font-size: 1rem;
	padding-left: 10px;
	transition: border 300ms;
	width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

@media only screen and (max-width: 720px) {
	.inputGetLife-input,
	.inputGetLife-input-error {
		width: auto !important;
	}
}
.inputGetLife-onBlurGrey {
	font-family: $font-family-getlife;
	font-weight: 400;
	height: 48px;
	border-radius: 8px;
	border: solid 2px $border;
	font-size: 1rem;
	padding-left: 10px;
	transition: border 300ms;
	transition: box-shadow 300ms;
	width: 100%;
	background-color: $white;
	color: $text;
}
.inputGetLife-label {
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.54;
	letter-spacing: normal;
	text-align: left;
	color: $label;
}

.inputGetLife-labelAnswered {
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.54;
	letter-spacing: normal;
	text-align: left;
	color: $text !important;
}
