@import "./../../../../styles/variables.scss";

.claseProductoCard {
    background-color: $bg-card;
    padding: 20px;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.product-box {
    width: 50%;
    justify-content: flex-start;
}

.carrier-box {
    min-width: 100px;
    justify-content: center;
    display: flex;
    padding: 10px 5px 10px 5px;
    border: 1px solid $white;
    background-color: $white;
    color: $primary;
    border-radius: 8px;
    font-weight: 700;
    font-family: $font-family-getlife;
    font-size: 14px;
}

.claseProductoCard.incompleto {
    background-color: $bg-card-incomplete;
}

.claseProductoCard h5 {
    font-size: 12px;
    font-weight: 400;
}

.claseProductoCard p {
    font-size: 16px;
    font-weight: 700;
}

.claseProductoCard .starts {
    padding-top: 20px;
}

.claseProductoCard .starts .css-ptiqhd-MuiSvgIcon-root {
    font-size: 10px;
}

.precioProductoCard {
    background-color: $white;
    padding: 20px;
    border-radius: 0 0 8px 8px;
    color: $action-color;
    width: 100%;
}

.precioProductoCard.incompleto {
    color: $alert-color;
}

.precioProductoCard .precioMes {
    font-size: 36px;
    font-weight: 700;
}

.precioProductoCard .precioAnual {
    font-size: 16px;
    font-weight: 600;
}

.price-box {
    width: 70%;
}

.more-info-box {
    width: 30%;
}

.box-up {
    display: flex;
    flex-direction: row;
}

.precioProductoCard .questionPendings {
    font-size: 10px;
    font-weight: 600;
    height: 10px;
}

.precioProductoCard button {
    margin: 50px 0 0 0;
    height: 48px;
    width: 100%;
    font-size: 16px;
}

.more-info-box button {
    font-size: 14px;
    font-weight: 400;
    color: $text;
    text-decoration: underline;
    margin: 0px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    &:hover{
        background-color: transparent
    }
}

.buttons-container {
    display: flex;
    justify-content: space-between;
}

.first-button {
    width: 50%;
}

.second-button {
    width: 50%;
    margin-left: 5px;
    &__icon{
        display: flex;
        justify-content: center;
        border: solid 2px $primary;
        &:hover{
            border: solid 2px $primary;
            background-color: $primary-hover !important;
        }
    }
}

.second-button button {
    background-color: $white;
    color: $primary;
}

.second-button button:hover {
    background-color: transparent;
}
