@import './../../../styles/variables.scss';

.question-mainContainer {
    background-color: $white;
    border-radius: 8px;
    min-height: 90px;
    border: solid 0.5px $border;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.question-mainContainerAnswered {
    background-color: $bg-web;
    border-radius: 8px;
    min-height: 90px;
    border: solid 0.5px $border;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.question-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;
    width: 100%;
    align-items: center;
    align-content: center;
}

.question-containerVelogica {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    width: 100%;
    align-items: flex-start;
    align-content: center;
}

.question-containerVelogica .question-inputContainer {
    width: 100%;
}

.question--textContainer {
    width: 55%;
}

.question-question {
    color: $label;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
}

.question--textContainer__info {
    color: $label;
    font-size: 14px;
    font-weight: 500;
    padding-top: 4px;
}

.questions-downgraded-dialog-close {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0 !important;
}

.questions-downgraded-dialog-content {
    padding: 20px !important;
    background-color: white;
}

.questions-downgraded-dialog-content p {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    text-align: center;
}

.questions-downgraded-dialog-content span {
    font-size: 20px;
    font-weight: 700;
    color: $action-color;
}

.question-inputContainer {
    display: flex;
    flex-direction: column;
    width: 39%;
    padding-left: 30px;
    p {
        color: $label;
        font-size: 13px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1.54;
        padding-bottom: 5px;
    }
  
}

.continue-button{
    width: 160px ;
    max-height: 48px ;
    margin-left: 10px;
}

.question-inputContainer .selectGetLife-container div {
    margin-top: 0 !important;
}

.question-inputContainer .inputGetLife-container > input {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}



.pencilButton-container {
    margin-top: 0;
}

.question-questionalert {
    text-align: center;
    width: 100%;
    color: $error;
}

.question-withoutEditButton {
    width: 167px;
    min-width: 167px;
}

@media only screen and (max-width: 616px) {
    .question-container {
        flex-direction: column;
        padding: 33px 22px 42px 22px;
        align-items: center;
    }

    .question-containerVelogica {
        flex-direction: column;
        padding: 33px 22px 42px 22px;
        align-items: center;
    }

    .question-question {
        width: 100%;
    }

    .question-inputContainer {
        width: 100%;
        padding-left: unset;
        margin-top: 10px;
    }

    .addQuestionButton-MainContainer {
        width: 100%;
        margin-left: unset;
        margin-top: 10px;
    }
    .question-pencilButtonContainer {
        width: 100%;
        margin-top: 10px;
    }
    .pencilButton-container {
        width: 100%;
        margin-left: unset;
    }
}

@media only screen and (max-width: 440px) {
    .question-inputContainer {
        flex-wrap: wrap;
    }
    
    .addButton-main {
        margin-top: 10px;
    }

    .question-question {
        font-size: 14px;
    }
}
