@import "./../../../../styles/variables.scss";

.button-primary {
	align-items: center;
	background-color: $primary;
	border-radius: 8px;
	border: none;
	color: $white;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-family: $font-family-getlife;
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	font-weight: bold;
	height: 48px;
	justify-content: center;
	letter-spacing: normal;
	line-height: normal;
	min-width: 157px;
	padding: 10px;
	text-align: center;
	box-shadow: 0 10px 20px 0 $shadow;
	.MuiCircularProgress-root {
		color: white;
	}
}

.button-primary:hover {
	background-color: $primary-hover;
	transition: 0.3s;
	box-shadow: none;
}

.button-primary-disabled {
	align-items: center;
	background-color: $primary-disabled;
	opacity: 0.4;
	border-radius: 8px;
	border: none;
	color: $white;
	cursor: not-allowed;
	display: flex;
	flex-direction: row;
	font-family: $font-family-getlife;
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	font-weight: bold;
	height: 48px;
	justify-content: center;
	letter-spacing: normal;
	line-height: normal;
	min-width: 157px;
	padding: 10px;
	text-align: center;
	box-shadow: none;
}

@media only screen and (max-width: 720px) {
	.button-primary {
		width: 100% !important;
	}
	.button-primary-disabled {
		width: 100% !important;
	}
}
