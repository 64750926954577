@import './../../../styles/variables.scss';

.changePassword {
    background-color: $bg-web;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.changePassword--main {
    width: 360px;
    height: 375px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border-bottom: solid 1px $border;
}

.changePassword--main__img {
    width: 120px;
    margin: 35px 0 25px 0;
}

.changePassword--main__happyFace {
    width: 47px;
    margin: 25px 0 0 0;
}

.changePassword--main__title {
    width: 192px;
    font-family: $font-family-getlife;
    font-size: 12px;
    text-align: center;
    margin:0 0 36px 0;
    margin: 21px 0 0 0;
}

.changePassword--main__btn {
    background-color: $primary;
    width: 275px;
    height: 55px;
    font-family: $font-family-getlife;
    font-size: 16px;
    font-weight: bold;
    color: $white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin: 40px 0 41px 0;
    padding: 20;
}

