@import './../../../../styles/variables.scss';

.modalMoreInfo {
    width: calc(100vw - 100px);
    padding: 20px 21px 20px 80px;
}

.modalMoreInfo .closeIcon {
    background-color: transparent;
    color: $primary;
    cursor: pointer;
    width: 37px;
    height: 37px;
}

// .modalMoreInfo .MuiSvgIcon-root {
//     width: 37px;
// }

.modalMoreInfo .tittle {
    font-family: $font-family-getlife-secondary;
    font-weight: 400;
    font-size: 36px;
    padding-top: 10px;
    color: $primary;
}

.modalMoreInfo label {
    color: $primary;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.modalMoreInfo p {
    line-height: 1.7;
}

.modalMoreInfo p span {
    font-weight: 700;
    color: $primary;
}

.modalMoreInfo h4 {
    color: $primary;
    font-family: $font-family-getlife-primary;
    font-weight: 700;
    font-size: 24px;
}

.infoSecondary {
    color: $text !important;
    margin-bottom: 15px;
    margin-top: 8px;
}

.intro {
    width: 40%;
}

.intro p {
    color: $text;
    font-family: $font-family-getlife-primary;
    font-size: 16px;
}

.intro span {
    color: $primary;
}

.box-info {
    margin-top: 30px;
    width: 100%;
    min-height: 300px;
    border-radius: 20px;
    background-color: $bg-web;
    display: flex;
    flex-direction: row;
}

.button-container-modal {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
}

.general-conditions {
    width: 20%;
    min-height: 300px;
    border-right: 1px solid #414141;
    flex-direction: column;
    padding: 37px;
    justify-content: center;
}

.inclusions-box {
    width: 40%;
    min-height: 300px;
    border-right: 1px solid #414141;
    padding: 37px;
    font-family: $font-family-getlife-primary;
    h4 {
        padding-bottom: 36px;
    }
    &--check {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 22px;
        &__text {
            width: 350px;
            font-size: 18px;
            line-height: 20px;
            color: $text;
            font-weight: 700;
            padding-left: 16px;
        }
    }
    &--check .MuiSvgIcon-root {
        color: $primary;
        height: 32px;
        width: 32px;
    }
}

.exclusions-box {
    width: 40%;
    min-height: 300px;
    padding: 37px;
    font-family: $font-family-getlife-primary;
    h4 {
        padding-bottom: 36px;
        color: #ff9900;
    }
    &--check {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 22px;
        &__text {
            width: 350px;
            font-size: 18px;
            line-height: 20px;
            color: $text;
            font-weight: 700;
            padding-left: 16px;
        }
        &__textdownload {
            padding-left: 0;
            width: 350px;
            font-size: 18px;
            line-height: 20px;
            color: $text;
            font-weight: 700;
            padding-top: 30px;
        }
    }
    &--check .MuiSvgIcon-root {
        color: #ff9900;
        height: 32px;
        width: 32px;
    }
}
