.IconCards {
  background-color: var(--theme-tertiary);
  overflow: hidden;
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 64px;
    padding-right: 24px;
    padding-left: 24px;
    text-align: center;
    @media (min-width: 768px) {
      gap: 64px;
    }
    @media (min-width: 1280px) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  &-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (min-width: 768px) {
      gap: 16px;
    }
  }
  &-mainTitle {
    font-family: "DM Sans";
    font-weight: 700;
    color: #424242;
    span {
      font-family: "Merriweather";
      font-weight: 300;
    }
  }
  &-mainDescription {
    color: #424242;
    font-weight: 500;
  }
  &-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;
  }
  &-card {
    padding: 24px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    flex: 352px;
    max-width: 352px;
    background-color: #ffffff;
  }
  &-icon {
    display: block;
    width: fit-content;
    background-color: #f5f7fa;
    color: var(--theme-primary);
    padding: 8px;
    border-radius: 100%;
    margin-bottom: 8px;
  }
  &-title {
    color: #424242;
    font-weight: 700;
  }
}
