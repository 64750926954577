@import './../../../../styles/variables.scss';

.selectGetLife-label {
    font-family: $font-family-getlife;
    color: $label;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.54;
    text-align: left;
}

.selectGetLifeRange::placeholder {
    color: $text;
    font-weight: 600;
}
