@import './../../../styles/variables.scss';

.reset {
	background-color: $bg-web;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.reset--main {
	width: 360px;
	height: 327px;
	background-color: $white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 8px;
	border-bottom: solid 1px $border;
}

.reset--main__img {
	width: 120px;
	margin: 35px 0 25px 0;
}

.reset--main__title {
	font-family: $font-family-getlife;
	font-size: 12px;
	margin: 0 0 36px 0;
}

.reset--main__form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 275px;
}

.reset--form__btn {
	margin: 15px 0 47px 0 !important;
}

.makeStyles-button-5 {
	color: $border !important;
}

.MuiButton-label {
	text-transform: capitalize;
	font-family: $font-family-getlife;
	font-size: 12px;
	font-weight: bold;
	color: $border;
}
