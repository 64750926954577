@import "./../../../../styles/variables.scss";

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.modal-header {
    display: flex;
    justify-content: space-between;
}

.modal.enter-done {
    opacity: 1;
    pointer-events: visible;
}

.modal.exit {
    opacity: 0;
}
.modal-close {
    color: $black;
    cursor: pointer;
    align-self: center;
    margin-left: 10px;
}
.modal-content {
    min-width: 500px;
    width: max-content;
    background-color: $white;
    transition: all 0.3s ease-in-out;
    transform: translateY(-200px);
    padding: 20px;
}

.modal.enter-done .modal-content {
    transform: translateY(0);
}

.modal.exit .modal-content {
    transform: translateY(-200px);
}

.modal-header,
.modal-footer {
    padding: 10px;
}

.modal-title {
    margin: 0;
}

.modal-body {
    padding: 10px;
}

@media only screen and (max-width: 768px) {
    .modal-content {
        min-width: 300px;
    }
}
