@import './../../../styles/variables.scss';

.ModalTalkWithExpert {
    width: calc(100vw - 2rem);
    color: $primary;
    border-radius: 8px;
    background: $white url('../../../assets/img/teleoperadora.png') no-repeat bottom right;
    background-size: 300px auto;
    padding-bottom: 180px;
}

.ModalTalkWithExpert--container {
    padding: 2rem;
}

.ModalTalkWithExpert--close {
    text-align: right;
    font-weight: 600;
    font-size: 24px;
    margin: 6px;
}

.ModalTalkWithExpert--title {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    margin: 0 0 0 0;
}

.ModalTalkWithExpert--title--last {
    margin-bottom: 32px !important;
}

.ModalTalkWithExpert--text {
    font-size: 14px;
    padding: 0;
    margin: 20px 0;
}

.ModalTalkWithExpert--span {
    color: $action-color;
}

.ModalTalkWithExpert--butons__div {
    margin-bottom: 20px;
    max-width: 360px;
    position: relative;
}

.ModalTalkWithExpert--img {
    width: 298px;
    height: 186px;
    margin-top: 33px;
    margin-bottom: 27px;
}

.ModalTalkWithExpert--buttons {
    margin-top: 40px;
}

.ModalTalkWithExpert--buttons__btn {
    flex-grow: 0;
    padding: 12px 32px;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 $shadow;
    background-color: $primary-shadow;
    text-decoration: none;
    color: $primary;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    width: 100%;
    font-family: $font-family-getlife !important;
    border: none;
    margin-right: 16px;
    text-align: left;
    box-sizing: border-box;
    margin-bottom: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.ModalTalkWithExpert--buttons__btn:hover {
    background-color: $primary-hover;
}

.ModalTalkWithExpert--buttons__btn--primary {
    background-color: $primary;
    color: white;
}

.ModalTalkWithExpert--buttons__btn--primary:hover {
    background-color: $primary-hover;
}

.ModalTalWithExpert--buttons__anchor {
    text-decoration: none;
    color: white;
}

.ModalTalkWithExpert--buttons__btn svg {
    height: 20px;
    margin-right: 8px;
}

.ModalTalkWithExpert--buttons__call {
    background-color: $primary;
    color: white;
}

@media (min-width: 769px) {
    .ModalTalkWithExpert {
        width: 1238px;
        max-width: calc(100vw - 32px);
        background-size: 436px auto;
        padding-bottom: 0;
    }

    .ModalTalkWithExpert--container {
        padding: 5rem;
    }

    .ModalTalkWithExpert--close {
        margin: 20px;
    }
    .ModalTalkWithExpert--title {
        font-size: 32px;
        margin: 0 0 0 0;
    }
    .ModalTalkWithExpert--text {
        font-size: 16px;
    }
    .ModalTalkWithExpert--img {
        transform: scaleX(-1);
        width: 450px;
        height: 280px;
        margin-top: 33px;
        margin-bottom: 0;
    }

    .ModalTalkWithExpert--content {
        width: calc(100% - 160px);
    }
}

@media (min-width: 1000px) {
    .ModalTalkWithExpert--content {
        width: calc(100% - 160px);
    }

    .ModalTalkWithExpert--butons__div {
        max-width: none;
        display: flex;
    }

    .ModalTalkWithExpert--buttons__btn {
        width: auto;
        min-width: 296px;
    }
}
