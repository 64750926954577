@import "./../../../../styles/variables.scss";

.upsell-personalization {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
}
.upsell-personalization-card {
    background-color: #f7f7f7;
    width: 336px;
    height: 144px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.06), 0px 3px 4px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    .upsell-personalization-card-body {
        color: $primary;
    }
}

.upsell-inner-personalization-header {
    background-color: $white;
    display: flex;
    flex-direction: row;
    padding: 30px;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
}

.upsell-inner-personalization {
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
}

.upsell-personalization-title {
    font-size: 32px;
    font-weight: 700;
    color: $primary;
    padding-top: 20px;
    text-align: center;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
}

.upsell-inner-personalization-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.upsell-inner-personalization-slider {
    margin-top: 20px;
    margin-bottom: 50px !important;
}

.upsell-personalization-subtitle {
    font-size: 16px;
    font-weight: 700;
    color: $primary;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
}

.upsell-inner-personalization-title {
    font-size: 12px;
    font-weight: 500;
    color: $text;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.upsell-personalization-custom-capital-title {
    font-size: 16px;
    font-weight: 700;
    margin-left: 100px;
    color: $primary;
    padding-bottom: 20px;
}

.upsell-personalization-custom-capital-input-container {
    margin-left: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
    padding-bottom: 30px;

    input {
        width: 90%;
        font-family: $font-family-getlife !important;
        font-weight: 400;
        height: 36px;
        border-radius: 8px;
        border: solid 2px $border;
        font-size: 1rem;
        padding-left: 10px;
        transition: border 300ms;
        transition: box-shadow 300ms;
    }

    label {
        height: 0 !important;
        padding: 0;
        margin: 0;
    }
}

.upsell-personalization-custom-capital-input {
    font-family: $font-family-getlife !important;
    font-weight: 400;
    height: 36px !important;
    border-radius: 8px;
    border: solid 2px $border;
    font-size: 1rem;
    padding: 0 0 0 10px;
    transition: border 300ms;
    transition: box-shadow 300ms;
    width: 50%;
}


.upsell-personalization-custom-capital-button {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-left: 10px;
    font-family: $font-family-getlife;
    font-size: 12px;
    font-weight: 700;
    color: $text;
    background-color: $bg-header;
    border: solid 0;
    border-radius: 5px;
    width: 76px;
}

.upsell-personalization-custom-capital-button:hover {
    background-color: $input-hover;
    transition: 0.3s;
}

.upsell-personalization-custom-capital-addon-container {
    padding-top: 20px;
    padding-bottom: 34px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    width: 680px;
}

.upsell-personalization-custom-capital-ipa-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;
    margin-left: 10px;
}

.upsell-personalization-custom-capital-addon-text {
    margin-left: 70px;
    margin-right: 70px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}

.upsell-personalization-custom-capital-ipa-title {
    font-size: 16px;
    font-weight: 700;
    color: $primary;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
}

.upsell-personalization-custom-capital-ipa-subtitle {
    padding-top: 5px;
    font-size: 14px;
    font-weight: 500;
    color: $text;
    line-height: 20px;
}

.upsell-personalization-add-button {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-left: 10px;
    font-family: $font-family-getlife;
    font-size: 12px;
    font-weight: 700;
    color: $white;
    background-color: $primary;
    border: solid 0;
    border-radius: 5px;
    width: 80px;
    cursor: pointer;
}

.upsell-personalization-add-button-xl {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding-right: 20px;
    padding-left: 20px;
    font-family: $font-family-getlife;
    font-size: 16px;
    font-weight: 700;
    color: $white;
    background-color: $primary;
    border: solid 0;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
}

.upsell-personalization-add-button:hover {
    background-color: $primary-hover;
    transition: 0.3s;
}

.upsell-personalization-add-button-xl:hover {
    background-color: $primary-hover;
    transition: 0.3s;
}

.upsell-personalization-delete-button {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-left: 10px;
    font-family: $font-family-getlife;
    font-size: 12px;
    font-weight: 700;
    color: $text;
    background-color: $bg-header;
    border: solid 0;
    border-radius: 5px;
    width: 120px;
}

.upsell-personalization-delete-button:hover {
    background-color: $input-hover;
    transition: 0.3s;
}

.upsell-personalization-custom-capital-addon-container-image {
    margin-left: 0;
}

.upsell-personalization-custom-capital-addon-price-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    padding-top: 10px;
}

.upsell-personalization-custom-capital-addon-price-previous {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-height: 36px;
    background-color: $white;
    color: $text;
    border-radius: 5px;
    padding: 0 15px;
}

.upsell-personalization-custom-capital-addon-price-now {
    padding-left: 20px;
    color: $action-color;
}

.upsell-personalization-first-row {
    width: 100%;
    padding: 30px;
    div{
        padding: 0px;
        margin: 0;
        max-width: 100%;
        z-index: 0 !important;
    }
    p{
        font-size: $font-m;
    }
    & .text-purple{
        font-size: $font-lg;
    }

}

.upsell-personalization-second-row {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    padding: 20px;
    &__coupon{
        display: flex;
        &--buttonText{
            justify-content: flex-start;
            align-items: center;
            text-decoration: underline;
            svg {
                margin: 0px 10px;
            }
        }
    }
}

.upsell-personalization-second-row-left {
    width: 50%;
    margin-right: 16px;
}

.upsell-container-guarantees {
    display: flex;
    padding: 30px;
    width: 100%;
    column-gap: 10px;
}
.upsell-personalization-second-row-right {
    width: 50%;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
}

.upsell-personalization-third-row {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $white;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .upsell-personalization {
        width: 100%;
        border-radius: 0;
        margin-top: 0;
    }

    .upsell-personalization-subtitle {
        margin: 20px;
    }

    .upsell-inner-personalization {
        width: calc(100% - 20px);
        border-radius: 0;
        margin-top: 0;
    }

    .upsell-inner-personalization-content {
        width: calc(100% - 36px);
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    .upsell-personalization-custom-capital-input {
        margin-bottom: 20px;
        width: 100% !important;
    }

    .upsell-personalization-custom-capital-title {
        font-size: 14px;
        font-weight: 700;
        color: $primary;
        padding-bottom: 20px;
        width: 100%;
        margin: unset;
    }

    .upsell-personalization-custom-capital-addon-container {
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .upsell-personalization-custom-capital-addon-container-image {
        margin: 0;
    }

    .upsell-personalization-custom-capital-ipa-text {
        margin-left: 0px;
        width: 90%;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-right: 0px;
    }

    .upsell-personalization-custom-capital-addon-text {
        margin-left: 0px;
        width: calc(100% - 40px);
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-right: 0px;
    }

    .upsell-personalization-add-button {
        width: calc(100% - 40px);
        margin-left: 0;
    }

    .upsell-personalization-title {
        font-size: 26px;
        line-height: 34px;
        margin: 20px;
    }

    .upsell-personalization-second-row {
        flex-direction: column;
        width: 100%;
    }

    .upsell-personalization-second-row-left {
        width: 100%;
    }

    .upsell-personalization-second-row-right {
        width: 100%;
    }
}
