@import '../../../../../styles/variables.scss';

.custom-table__footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 8px 12px;
	.custom-table__rowsper {
		label {
			font-family: $font-family-getlife;
			font-weight: 600;
			font-size: 13px;
		}
		select {
			margin-left: 10px;
		}
	}
	.custom-table__pagination {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 24px;
		.custom-table__pagination__totals {
			font-family: $font-family-getlife;
			font-weight: 600;
			font-size: 13px;
		}
		.custom-table__pagination__buttons {
			display: flex;
			flex-direction: row;
			button {
				cursor: pointer;
				pointer-events: inherit;
				color: #555555;
				padding: 2px;
				&:hover, &:focus {
					background: #D9D9D9;
					border-radius: 50%;
				}
				&.disabled {
					pointer-events: none;
				}
			}
		}
	}
}
