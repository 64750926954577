@import "./../../../../../styles/variables.scss";

.button {
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-family: $font-family-getlife;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    height: 48px;
    justify-content: center;
    letter-spacing: normal;
    line-height: normal;
    min-width: 157px;
    padding: 10px;
    text-align: center;
    width: 100%;

    .MuiCircularProgress-root {
        color: white;
    }
}

.button-primary {
    color: $white !important;
    border: none;
    background-color: $primary;
    box-shadow: 0 10px 20px 0 $shadow;
}

.button-text {
    background: none;
    color: $primary;
    -webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    align-items: flex-start;
    height: 16px;
    padding: 0px;
    border: none;
}

.button-primary:hover {
    background-color: $primary-hover;
    color: $primary;
    transition: 0.3s;
    box-shadow: none;
}

.button-disabled {
    text-align: center;
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
    border: none;
    pointer-events: none;
}

.button-text-disabled {
    color: $primary-disabled;
    align-items: flex-start;
    height: 16px;
    padding: 0px;
}

.button-primary-disabled {
    background-color: $primary-disabled;
    color: $white;
}

.button-secondary-disabled {
    background-color: $secondary-disabled;
    color: $primary;
}

.button-secondary {
    border: 2px solid $border-secondary !important;
    cursor: pointer;
    color: $primary;
    background-color: $secondary;
}

.button-secondary:hover {
    background-color: $secondary-hover !important;
    transition: 0.3s;
    color: $primary;
}

@media only screen and (max-width: 720px) {
    .button-primary {
        width: 100% !important;
    }
    .button-primary-disabled {
        width: 100% !important;
    }
}
