.LandingCalculator {
  overflow: hidden;
  &-wrapper {
    display: grid;
    gap: 32px;
    align-items: center;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 80px;
    padding-right: 24px;
    padding-left: 24px;
    @media (min-width: 768px) {
      padding-top: 80px;
      grid-template-columns: repeat(12, 1fr);
    }
    @media (min-width: 1280px) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  &-calculatorWrapper {
    max-width: 448px;
    position: relative;
    @media (min-width: 768px) {
      grid-column: span 5 / span 5;
      max-width: unset;
    }
    h2 {
      color: #424242;
      font-weight: 700;
      font-family: "DM Sans";
      .font-MW {
        font-family: Merriweather;
        font-weight: 300;
      }
    }
  }
  &-calculator {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 24px 32px;
    gap: 24px;
    border-radius: 24px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1),
      0px 1px 18px 0px rgba(0, 0, 0, 0.06), 0px 6px 10px 0px rgba(0, 0, 0, 0.07);
    @media (max-width: 390px) {
      padding: 16px 16px 24px;
    }
    @media (min-width: 768px) {
      max-width: 450px;
    }
  }
  &-subtitle {
    color: var(--theme-secondary);
    margin-top: 8px;
    font-weight: 500;
    @media (min-width: 768px) {
      margin-top: 16px;
    }
  }
  &-loopingArrow {
    display: none;
    position: absolute;
    bottom: 80px;
    right: -270px;
    z-index: 1;
    @media (min-width: 768px) {
      display: block;
    }
    svg {
      width: 303px;
      height: 303px;
    }
  }
  &-imageHeight {
    height: 260px;
    left: var(--heroCalculatorImageOffset);
    position: relative;
    @media (min-width: 768px) {
      height: var(--heroCalculatorImageHeight);
      grid-column-start: 7;
      grid-column-end: 13;
    }
  }
  &-image {
    height: 100%;
    position: absolute;
    width: 100vw;
    overflow: hidden;
    max-width: unset;
    object-fit: cover;
    object-position: left;
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
    @media (min-width: 768px) {
      width: 50vw;
    }
  }
}
