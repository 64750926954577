.LandingContact {
  background-color: var(--theme-quaternary);
  &-wrapper {
    padding-top: 64px;
    padding-bottom: 64px;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media (min-width: 1280px) {
      padding-left: 0px;
      padding-right: 0px;
    }
    h2 {
      font-family: "DM Sans";
      font-weight: 700;
      color: var(--theme-primary);
    }
    p {
      color: #424242;
    }
  }
}
