@import './../../../styles/variables.scss';

.omni-search___content {
	min-width: 400px;
	input {
		margin-bottom: 0;
	}
	label {
		margin-bottom: 0;
		font-family: $font-family-getlife;
		font-weight: 600;
		font-size: 12px;
	}
}
