@import './../../../../styles/variables.scss';

.upsell-personalization-custom-capital-ipa-container {
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    width: 100%;
}

.upsell-personalization-custom-capital-ipa-text {
    width: 70%;
}

.buttons-container-guarantees {
    width: 30%;
}

.button-card-guarantee {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    font-family: $font-family-getlife;
    font-size: 16px;
    font-weight: 700;
    color: $white;
    background-color: $primary;
    border: solid 0;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 15px;
}

.selected {
    color: $primary !important;
    background-color: $white !important;
    border: solid 2px $primary !important;
    border-radius: 8px;
}

.buttons-container-guarantees .button-secondary {
    margin-top: 5px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    font-family: $font-family-getlife;
    font-size: 16px;
    font-weight: 700;
    color: $primary;
    background-color: $white;
    border: solid 0;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
}
