.editProjects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  &-column {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &-estimatedPrice {
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: #ecf0ff;
    border-radius: 8px;
    & > * + * {
      margin-top: 24px;
    }
  }
  &-estimatedTitle {
    color: #555;
  }
  &-downloadableButton {
    width: fit-content;
    flex: 1;
    justify-content: flex-start;
  }
  &-CTA {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 40px;
    & button {
      flex: 1;
    }
  }
}
