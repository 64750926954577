@import '../../../styles/variables.scss';

.payment {
    &--title {
        font-size: 32px;
        font-weight: 700;
        font-family: $font-family-getlife;
        color: $primary;
        margin-bottom: 25px;
    }
    &--section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 109px;
        &__left {
            margin-bottom: 40px;
            .text {
                width: 388px;
                font-size: 16px;
                line-height: 24px;
                color: $primary;
                font-weight: 400;
                padding-bottom: 20px;
                padding-top: 32px;
            }
            .bold {
                font-weight: 600;
                padding: 0;
            }
        }
        &__right {
            width: 610px;
            border-radius: 12px;
            // box-shadow: 0px 1px 3px 0px #0000001A;
            .title {
                width: 100%;
                font-size: 12px;
                color: $text;
                line-height: 20px;
                height: 30px;
                display: flex;
                align-items: center;
                padding-left: 13px;
                border-radius: 8px;
                margin-left: 0;
            }
            .radio {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 100%;
            }
        }
    }
}

.card-select-payment-type {
    background-color: $white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-height: 354px;
}

.card-select-payment-type .subcontainer {
    padding: 20px 20px 0px 20px;
}

.card-select-payment-type .button-container-payment {
    padding: 24px 20px 20px 20px;
}

.card-select-payment-type .container-card p {
    font-family: $font-family-getlife;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: $primary;
    margin-top: 15px;
}

.card-select-payment-type .button-container-payment button {
    width: 100%;
}

.card-select-payment-type h5 {
    font-family: $font-family-getlife;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $primary;
    margin-bottom: 20px;
}

.card-select-payment-type p {
    font-family: $font-family-getlife;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $primary;
}

.card-select-payment-type .customRadioButton-componentContainer {
    padding: 20px;
}

.MuiSvgIcon-root {
    fill: #E2E2E2 !important;
}

.checked .MuiSvgIcon-root {
    fill: $primary !important;
}

#customRadioButom-radio > label.MuiFormControlLabel-root > input{
    border-color: #424242;
    background-color: #424242;
}

#customRadioButom-radio > label:nth-child(1) > span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;
    font-family: $font-family-getlife;
    color: #424242;
}

#customRadioButom-radio > label.MuiFormControlLabel-root.checked > span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: $primary !important;
    font-weight: 700;
}

#customRadioButom-radio > label:nth-child(2) > span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;
    font-family: $font-family-getlife;
    color: #424242;
}


#customRadioButom-radio > label:nth-child(3) > span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;
    font-family: $font-family-getlife;
    color: #424242;
}


.card-select-payment-type .customRadioButton-componentContainer span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;
    font-family: $font-family-getlife;
    color: $primary;
}

.card-select-payment-type .customRadioButton-componentContainer p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;
    font-family: $font-family-getlife;
    color: #424242;
}

.container-legal-text {
    background: $bg-web;
    box-shadow: 0px 10px 20px $shadow;
    border-radius: 8px;
    margin-bottom: 30px;
}

.container-legal-text .customRadioButton-componentContainer span {
    font-family: $font-family-getlife;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: $primary;
}
.container-legal-text .customRadioButton-componentContainer span a {
    font-family: $font-family-getlife;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: $primary;
}

input .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: $font-family-getlife;
    box-shadow: $shadow 0px 1px 3px, $shadow 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: $white;
    padding-left: 27px;
}

input .StripeElement::placeholder {
    color: $border-hover;
}

input .StripeElement--focus input:focus {
    box-shadow: $shadow 0px 4px 6px, $shadow 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

.StripeElement.PaymentRequestButton {
    height: 40px;
}

.re-send-otp {
    border: none;
    background-color: $white;
    margin-top: 20px;
    text-align: start;
    color: $primary;
    cursor: pointer;
}

.re-send-otp span {
    font-weight: 700;
}

.card-select-payment-type .button-container-payment .more-info {
    border: none;
    background-color: $primary;
    color: $white;
    border-radius: 8px;
    cursor: pointer;
    padding: 3px;
    font-weight: 700;
}

.card-select-payment-type .button-container-payment span {
    font-family: $font-family-getlife;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: $primary;
}

@media (max-width: 768px) {
    .payment--section {
        &__left {
            width: 100%;
        }

        &__right .radio .customRadioButton-componentContainer {
            margin-left: 0;
        }
    }
}
