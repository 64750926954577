@import "./../../styles/variables.scss";

.employees {
	&__buttons {
		display: flex;
		flex-direction: row;
		gap: 8px;
		button {
			width: fit-content;
			padding: 12px 16px;
		}
	}
}
.employees__container {
	display: flex;
	align-items: center;
	align-content: center;
	width: 100%;
	padding: 20px 0;
	flex-direction: column;
	// Temporal until can show edit button.
	.custom-table__body__cell {
		padding: 20px;
	}
}
.employees-modal-title {
	font-family: $font-family-getlife-primary;
}
.employees-body-modal {
	display: flex;
	flex-direction: column;
	width: 700px;
	.employees-details {
		display: flex;
		column-gap: 10px;
		.employees-input {
			width: 100%;
		}
	}
}

.employees-invitation-buttons {
	display: flex;
	justify-content: flex-end;
	column-gap: 20px;
}

.employees-injection {
	min-width: 50vw;
}