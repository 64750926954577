@import "./../../styles/variables.scss";

body {
  background-color: #f5f7fa;
}

.pointer {
  cursor: pointer;
}

.dashboard-mainContainer {
  display: flex;
  justify-content: center;
}

.dashboard-container {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 164px);
}

.dashboard-leftContainer {
  width: 43%;
  padding-top: 33px;
}

.dashboard--alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  background-color: $white;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 20px;
}

.dashboard--alert .MuiSvgIcon-root {
  color: #ff6a85;
}

.dashboard--alert__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: $font-family-getlife;
  color: $primary;
  padding-left: 16px;
}

.dashboard--alert__text span {
  font-weight: 700;
}

.dashboard-leftContainer > h1 {
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: normal;
}

.dashboard-leftContainer__text {
  font-size: 14px;
  font-weight: normal;
  margin: 1rem 0;
  color: $primary;
  width: 80%;
}

.dashboard-leftContainer .MuiButton-containedNeutral {
  font-family: $font-family-getlife;
  text-transform: none;
  font-size: 16px;
  font-weight: bold;
  width: 277px;
  height: 48px;
}

.dashboarMain-bottomLink > p {
  color: $primary;
  cursor: pointer;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.26px;
  line-height: 1.54;
  text-align: left;
  text-decoration: underline;
}

.dashboarMain-bottomLink > img {
  cursor: pointer;
  height: 8.5px;
  width: 8.5px;
}

.dashboarMain-bottomLinksMainContainer {
  display: flex;
  flex-direction: column;
  width: 520px;
  padding-top: 133px;
}

.dashboarMain-bottomLinkHeader > p {
  color: $text;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.67;
  text-align: left;
}

.dashboarMain-bottomLink {
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: $white;
  margin-top: 1.5px;
  height: 50px;
  padding: 6px 14px 6px 14px;
  width: 100%;
}

.dashboard-topTipsContainer > p {
  color: $primary;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.67;
  padding-top: 24px;
  padding-bottom: 6px;
  margin-left: 24px;
}

.dashboard-bottomTipsContainer > p {
  color: $primary;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.67;
  padding-top: 24px;
  padding-bottom: 6px;
  margin-left: 24px;
}

.dashboarMain-buttonContainer {
  padding-top: 15px;
}

.dashboarMain-bottomLinkHeader {
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 6px 14px 6px 14px;
  width: 100%;
  background-color: $white;
}

.dashboard-rightContainer {
  padding-top: 40px;
  position: sticky;
  width: 520px;
  height: fit-content;
  margin-bottom: 50px;
}

.dashboard-rightContainerTopHeader {
  background-color: $bg-header;
  border-radius: 8px;
  height: 30px;
  padding: 6px 0 6px 14px;
  margin-top: 0.5rem;
}

.dashboard-rightContainerTopHeader > p {
  color: $text;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.67;
  text-align: left;
}

.dashboard-rightCopyLinkContainer {
  background-color: $white;
  border-radius: 8px;
  flex-grow: 0;
  height: 106px;
  padding: 29px 33px 49px 32px;
  width: 100%;
}

.dashboard-copyLinkContainer {
  display: flex;
  width: 100%;
}

.dashboard-copyLinkContainer .inputGetLife-container {
  width: 100%;
}

.dashboard-copyLinkGrid {
  align-items: baseline;
}

.dashboard-buttonLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.dashboard-buttonLinkContainer > button {
  min-width: 30px;
  margin-left: 7px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  font-weight: 600;
}

.dashboard-rightCopyLinkContainer > p {
  color: $text;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-decoration: underline;
}

.dashboard-rightContainerBottomHeader {
  background-color: $bg-header;
  border-radius: 8px;
  height: 30px;
  margin-top: 7px;
  padding: 6px 14px 6px 14px;
  display: flex;
  justify-content: space-between;
}

.dashboard-rightContainerBottomHeader-left {
  color: $text;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.67;
  text-align: left;
}
.dashboard-rightContainerBottomHeader-right {
  align-items: center;
  color: $text;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  justify-content: flex-end;
  letter-spacing: normal;
  line-height: 1.67;
  > * + * {
    margin-left: 4px;
  }
}

.dashboard-rightFormContainerFooter {
  background-color: $bg-header;
  padding: 3px 16px;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.dashboard-rightFormContainer {
  background-color: $white;
  height: fit-content;
  border-radius: 8px;
  padding: 18px 30px 36px 30px;
}

.dashboard-rightGridContainer {
  justify-content: center;
}

.dashboard-rightGridContainer > div > p {
  font-size: 14px;
  padding-bottom: 10px;
  font-family: $font-family-getlife !important;
  font-weight: 400;
}

.dashboard-birthDateContainer {
  display: flex;

  justify-content: space-between;
  width: 100%;
}

.dashboard-birthDateContainerMain {
  width: 100%;
}

.dashboard-formRows {
  padding-top: 22px;
}

.dashboard-buttonContainer {
  display: flex;
  padding-top: 36px;
  width: 100%;
  justify-content: flex-end;
}

.dashboard-buttonContainer > button {
  width: 100%;
}

.dashboard-formFooter {
  background-color: #e2e2e2;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  padding: 15px 30px 21px 30px;
  width: 100%;
}

.dashboard-formFooter.notOwner {
  height: auto;
}

.dashboard-formFooterData p:first-child {
  color: $text;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
}

.dashboard-formFooterData p:last-child {
  padding-top: 5px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: $text;
}

.dashboard-formFooterDataContainer {
  display: flex;
  justify-content: space-between;
}

.dashboard-buttonWhiteContainer > button {
  width: 100%;
  text-align: center;
}

.dashboard-formFooterGreyButContainer {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
}

.dashboard-formGreyButton {
  background-color: #e2e2e2;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-grow: 0;
  justify-content: center;
  height: 56px;
  margin: 1px 1px 0 0;
  padding: 16px;
  width: 374px;
  svg {
    margin-left: 8px;
  }
}

.dashboard-formGreyButton > p {
  color: $text;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.dashboard-conditionalWellcome {
  display: none;
}

.dashboard-formGreyButton > img {
  margin-left: 10px;
}

.dashboarMain-bottomLinkmdainContainer {
  margin-top: 133px;
}

.tooltip-copy {
  font-size: 10px;
  background-color: $text;
  color: white;
  border-radius: 5px;
  float: left;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  top: 5px;
}
.plus {
  margin-left: 5px;
  background-image: url("../../assets/img/MORE.svg");
}
.minus {
  margin-right: 5px;
  background-image: url("../../assets/img/LESS.svg");
}
.plus:hover {
  background-image: url("../../assets/img/MOREHOVER.svg");
}
.minus:hover {
  background-image: url("../../assets/img/LESSHOVER.svg");
}
.modifyButton {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: $white;
  background-position: center center;
  background-repeat: no-repeat;
}
.dashboard-capital {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.disabled-capital .price2 > input {
  background-color: $input-hover;
}
.disabled-capital .modifyButton {
  background-color: $input-hover;
  cursor: auto;
}
.disabled-capital .plus {
  background-image: url("../../assets/img/MOREHOVER.svg");
}
.disabled-capital .minus {
  background-image: url("../../assets/img/LESSHOVER.svg");
}
.price2 > input {
  font-family: $font-family-getlife !important;
  font-weight: 400;
  height: 31px;
  border-radius: 8px;
  border: solid 2px $border;
  font-size: 1rem;
  padding-left: 10px;
  transition: border 300ms;
  transition: box-shadow 300ms;
  width: 388px;
}
.price2::after,
.price2::before {
  border-bottom: none !important;
}

.dashboard-buttonWhiteContainer .inputGetLife-errorMessageContainer p {
  margin-top: 10px !important;
  font-weight: 700;
}

@media only screen and (max-width: 1200px) {
  .dashboarMain-bottomLinksMainContainer {
    display: flex;
    width: 410px;
  }
}

@media only screen and (max-width: 1098px) {
  .dashboard-rightContainer {
    width: 400px;
  }
  .dashboard-formGreyButton {
    width: 200px;
  }
  .price2 > input {
    width: 268px;
  }
}

@media only screen and (max-width: 899px) {
  .price2 > input {
    width: 208px;
  }
  .dashboard-conditionalWellcome {
    display: contents;
  }

  .dashboard-conditionalWellcome > h1 {
    padding-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: $primary;
  }

  .dashboard-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }

  .dashboard-mainContainer {
    justify-content: center;
  }

  .dashboard-birthDateContainer {
    flex-direction: column;
  }

  .dashboard-rightContainer {
    width: 340px;
    margin-top: 20px;
  }

  .dashboard-formGreyButton {
    width: 170px;
  }

  .dashboard-rightFormContainer {
    height: fit-content;
  }

  .dashboard-formFooterDataContainer {
    flex-wrap: wrap;
  }

  .dashboard-formFooter {
    flex-direction: column;
    height: 197px;
  }

  .dashboard-buttonWhiteContainer > button {
    margin-left: unset;
  }

  .dashboard-leftContainer {
    width: 338px;
    margin-top: 816px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dashboard-leftContainer > h1 {
    display: none;
  }

  .addButton-mainHeadPhone {
    margin-left: unset !important;
  }

  .dashboard-topTipsContainer {
    width: 338px;
  }

  .dashboard-bottomTipsContainer {
    width: 338px;
  }

  .dashboarMain-buttonContainer {
    width: 338px;
    align-items: center;
    text-align: center;
  }

  .dashboarMain-bottomLinkmdainContainer {
    width: 338px;
    margin-top: 68px;
  }
}

.newDashboard {
  &-grid {
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    padding: 32px;
    box-sizing: content-box;
    @media (min-width: 1024px) {
      grid-template-columns: 1fr 304px;
    }
  }
  &-calculator {
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: fit-content;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 24px 40px 32px 40px;
    box-shadow: 0px -1px 0px 0px rgba(66, 66, 66, 0.1) inset,
      0px 1px 3px 0px rgba(66, 66, 66, 0.1);
  }
  &-tariffifier {
    padding: 24px 24px 32px 24px;
    background-color: #f5f7fa;
    border-radius: 12px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
      0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.07);
  }
  &-tariffifierGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 48px;
  }
  &-tariffifierGridColumn {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &-tariffifierGuarantees {
    gap: 16px;
    background-color: #ecf0ff;
    border-radius: 8px;
    padding: 16px 24px 16px 24px;
  }
  &-guaranteeResult {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    color: #424242;
    font-weight: 500;
  }
  &-guaranteeDivider {
    border-top: 1px solid #d6d6f9;
  }
  &-resultsCTA {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 32px;
    button {
      flex: 1;
    }
  }
  &-estimatedPrice {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    color: #424242;
    gap: 8px;
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .H3 {
      color: var(--primary-color);
    }
  }
  &-promoPriceLabel {
    color: #7b7b7b;
  }
  &-coupons {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    font-weight: 700;
    color: #424242;
  }
  &-couponTitle {
    flex: 1;
  }
  &-addCoupon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #424242;
    .inputGetLife-container {
      width: 100%;
      margin-top: 8px;
      .inputGetLife-input,
      .inputGetLife-onBlurGrey {
        margin: 0 0 8px 0;
      }
    }
    .inputDS {
      width: 100%;
    }
    &.closed {
      overflow: hidden;
      max-height: 0px;
    }
    &.open {
      max-height: 200px;
    }
  }
  &-addCouponButton {
    margin-top: 20px;
  }
  &-couponAdded {
    width: 100%;
    display: none;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    &.validate {
      display: flex;
      color: #8e8e8e;
    }
    &.invalidate {
      display: flex;
      color: #e84b4b;
    }
  }
  &-removeCoupon {
    margin-right: auto;
    color: #8e8e8e;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    font-weight: 700;
    text-decoration: underline;
    margin-top: 8px;
  }
  &-fieldLabel {
    color: #424242;
    margin-bottom: 8px;
  }
  &-addRelatedClient {
    margin-left: auto;
  }
  &-tariffifierCTA {
    margin-left: auto;
  }
  &-coverageToggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    p {
      font-weight: 700;
    }
  }
}
