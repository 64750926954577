@import "../../../../styles/variables.scss";

.message-container {
    padding-bottom: 20px;
    display: flex;
    column-gap: 10px;
    max-width: fit-content;
    .message-text {
        font-size: 12px;
        padding-top: 1px;
    }
    .error {
        color: $error;
    }
    .warning {
        color: $alert-color;
    }
    .info {
        color: $primary;
    }

    .landing {
        color: $primary-hover;
        background-color: transparent;
    }
}
