@import './../../../../styles/variables.scss';

.addButton-main {
    background-color: $white;
    border-radius: 8px;
    border: none;
    box-shadow: 0 10px 20px 0 $shadow;
    color: $primary;
    cursor: pointer;
    font-family: $font-family-getlife;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    height: 48px;
    letter-spacing: normal;
    line-height: 1.67;
    padding: 0 13px 0 13px;
    text-align: left;
    width: 195px;
}
.addButton-imgContainer {
    display: flex;
    align-items: center;
}

.addButton-imgContainer > img {
    padding-left: 51px;
}

/*ESTILOS HEADPHONE*/
.addButton-mainHeadPhone {
    background-color: $white;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    color: $primary;
    cursor: pointer;
    font-family: $font-family-getlife;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    height: 48px;
    letter-spacing: normal;
    line-height: 1.67;
    margin-top: 30px;
    width: 277px;
}
.addButton-imgContainerHeadPhone > p {
    color: $primary;
    font-family: $font-family-getlife;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    margin: 0 0 2.6px 22.7px;
    text-align: center;
}
.addButton-imgContainerHeadPhone {
    display: flex;
    justify-content: center;
}

.disabled {
    background-color: $bg-header;
}
