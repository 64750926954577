@import './../../../styles/variables.scss';

.earningsMain-mainContainer {
	background-color: $bg-web;
	min-height: 100vh;
}
.earningsMain-container {
	margin: 0px 82px 0 82px;
	padding-top: 33px;
}

.earningsMain-headerTextContainer {
	display: flex;
	flex-direction: column;
	width: 50%;
}
.earningsMain-headerTextContainer > h1 {
	color: $primary;
	font-size: 32px;
	font-stretch: normal;
	font-style: normal;
	font-weight: bold;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;
}
.earningsMain-headerTextContainer > p {
	color: $primary;
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1.5;
	padding-top: 9px;
	text-align: left;
}
.earningsMain-earningsBoxContainer {
	margin-top: 106px;
}
.earningsMain-popUpMainContainer {
	display: flex;
	justify-content: flex-end;
	font-size: 12px;
}

.earningsMain-popUpMainContainer .selectGetLife-container {
	width: 170px;
	padding-bottom: 5px;
	font-weight: 600;
	font-size: 12px;
	font-family: $font-family-getlife;
	& *,
	& *:hover {
		border: none;
		// FIXME: no puedo personalizar más el Select.
	}
}

.earningsMain-popUpcontainer {
	align-items: center;
	background-color: $white;
	border-radius: 8px;
	display: flex;
	height: 40px;
	justify-content: space-between;
	margin-bottom: 7px;
	margin-right: 6px;
	padding: 11px 8.7px 9px 14px;
	width: 173px;
}

.earningsMain-popUpcontainer > p {
	color: $primary;
	font-family: $font-family-getlife;
	font-size: 12px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 1.67;
}
.earningsMain-popUpcontainer > img {
	height: 16.3px;
	width: 16.3px;
}

.earningsMain-totalEarningsBody {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.earningsMain-totalEarningsContainer {
	width: 100%;
	margin-right: 4px;
}
.earningsMain-totalEarnings {
	background-color: $white;
	border-radius: 8px;
	height: 30px;
	padding: 5px 0 5px 15px;
}

.earningsMain-totalEarnings > p {
	color: $text;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 1.67;
	text-align: left;
}
.earningsMain-footer {
	align-items: center;
	background-color: $white;
	border-radius: 8px;
	display: flex;
	height: 58px;
	justify-content: space-between;
	margin-top: 4px;
	padding-right: 15px;
}

.earningsMain-footer > p {
	color: $text;
	font-family: $font-family-getlife;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1.54;
	padding: 19px 15px 19px 15px;
}
.earningsMain-footer > div {
	background-color: $action-color;
	border-radius: 8px;
	height: 38px;
	padding: 0px 9px 0px 9px;
}
.earningsMain-footer > div > h1 {
	font-size: 32px;
	color: $white;
}
.earningsMain-footerPolicies {
	align-items: center;
	background-color: $white;
	border-radius: 8px;
	display: flex;
	height: 58px;
	justify-content: space-between;
	margin-top: 4px;
	padding-right: 15px;
}
.earningsMain-footerPolicies > p {
	padding: 19px 15px 19px 15px;
	color: $text;
	font-family: $font-family-getlife;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1.54;
}

.earningsMain-footerPolicies > div > h1 {
	font-size: 32px;
	color: $white;
}
.earningsMain-footerPolicies > div {
	background-color: $text;
	border-radius: 8px;
	height: 38px;
	padding: 0px 9px 0px 9px;
}

.earningsMain-booksMainContainer {
	margin-top: 100px;
	margin-bottom: 40px;
	// .earningsMain-totalEarnings {
	// }
}

.earningsMain-bookImg {
	width: 150px;
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
	padding: 10px;
	background-color: $bg-web;
}

.earningsMain-bookImg img {
	width: 100%;
	border-radius: 5px;
}

.earningsMain-boxRightContent {
	padding-left: 30px;
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	align-items: flex-start;
	justify-content: space-between;
}

.earningsMain-boxRightContent > div > p {
	color: $text;
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 1.25;
}

.earningsMain-boxFooter {
	margin-top: 40px;
}
.earningsMain-boxPdfContainer {
	background-color: $bg-web;
	border-radius: 8px;
	flex-grow: 0;
	height: 26px;
	padding: 3px 8px;
	width: fit-content;
}

p.fileType {
	color: $text;
	font-family: $font-family-getlife;
	font-size: 13px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 1.54;
}
.earningsMain-imgBoxFooterContainer {
	display: flex;
	margin-top: 10px;
}
.earningsMain-imgDownloadContainer {
	align-items: center;
	background-color: $primary;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-content: center;
	gap: 10px;
	height: 48px;
	justify-content: center;
	width: 48px;
	color: $white;
}

.earningsMain-imgDownloadContainer > a > img {
	width: 21px;
	height: 20px;
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(318deg) brightness(143%) contrast(102%);
	margin-top: 8px;
}

.earningsMain-ctaContainer {
	align-items: center;
	background-color: $primary;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	gap: 10px;
	height: 48px;
	justify-content: center;
	width: fit-content;
}

.earningsMain-ctaContainer a {
	font-size: 14px;
	font-weight: 600;
	color: $white;
	padding-right: 15px;
	padding-left: 15px;
	text-decoration: none;
}

.earningsMain-imgDownloadContainer > img {
	width: 21px;
	height: 20px;
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(318deg) brightness(108%) contrast(102%);
}
.earningsMain-imgShareContainer {
	align-items: center;
	background-color: $bg-web;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	gap: 10px;
	height: 48px;
	justify-content: center;
	margin-left: 7px;
	width: 48px;
}
.earningsMain-imgShareContainer > img {
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(318deg) brightness(108%) contrast(102%);
	height: 20px;
	width: 21px;
}

.earningsMain-webinarsContent {
	background-color: $white;
	border-radius: 8px;
	height: 30px;
	margin-top: 29px;
	padding: 5px 0 5px 15px;
}

.earningsMain-webinarsContent > p {
	color: $text;
	font-size: 12px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 1.67;
}
.earningsMain-video > img {
	width: 157px;
	height: 130px;
}

.earningsMain-videoBoxFooter {
	padding-top: 26px;
}

@media only screen and (max-width: 1200px) {
	.earningsMain-container {
		margin: 0px 28px 0 28px;
		padding-top: 33px;
	}
	.earningsMain-headerTextContainer {
		margin-left: 54px;
		width: 71%;
	}
}
@media only screen and (max-width: 992px) {
	.earningsMain-boxRightContent > div > p {
		color: $text;
		font-size: 15px;
		font-stretch: normal;
		font-style: normal;
		font-weight: 600;
		letter-spacing: normal;
		line-height: 1.25;
	}
}
@media only screen and (max-width: 768px) {
	.earningsMain-totalEarningsBody {
		flex-direction: column;
	}

	.earningsMain-footer {
		margin-bottom: 20px;
	}

	.earningsMain-headerTextContainer {
		margin-left: 24px;
	}
}
