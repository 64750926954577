@import './../../styles/variables.scss';

.welcomeMain {
	background-color: $bg-web;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.welcomeMain--container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: $primary;
}

.welcomeMain--container__title {
	font-size: 20px;
	padding-bottom: 11px;
}

.welcomeMain--container__text {
	font-size: 14px;
	line-height: 1.43;
}

.welcomeMain--container2 {
	height: 80px;
	width: 100%;
	background-color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 0;
	box-shadow: 0 10px 20px 0 $shadow;
}

.welcomeMain--container2__anchor {
	text-decoration: none;
	font-size: 16px;
	font-weight: bold;
	height: 48px;
	width: 321px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media all and (min-width: 768px) {
	.welcomeMain {
		padding: 0 20px 0 20px;
	}
	.welcomeMain--container__title {
		font-size: 40px;
		padding-bottom: 9px;
	}

	.welcomeMain--container__text {
		font-size: 16px;
		line-height: 1.5;
	}

	.welcomeMain--container2 {
		width: 94.79%;
		justify-content: flex-end;
		bottom: 29px;
		border-radius: 8px;
		box-shadow: 0 0 20px 0 $shadow;
	}

	.welcomeMain--container2__anchor {
		width: 314px;
		margin-right: 16px;
	}
}

@media all and (min-width: 992px) {
	.welcomeMain--container2 {
		width: 95.97%;
		bottom: 21px;
	}
}

@media all and (min-width: 1200px) {
	.welcomeMain--container2 {
		width: 96.66%;
	}
}

@media all and (min-width: 1440px) {
	.welcomeMain--container2 {
		width: 97.22%;
	}
}
