$primary: #423ef4;
$primary-hover: #f5c4dc;
$primary-disabled: #5957c8;
$primary-shadow: #e7e7fe;
$primary-shadow-hover: #d6acc1;

$secondary: #ffffff;
$border-secondary: $primary;
$secondary-hover: $primary-hover;
$secondary-disabled: rgba(231, 231, 254, 0.3);

$bg-web: #f3f3f3;
$bg-header: #ebebeb;
$white: #ffffff;
$border: #e7e6df;
$input-hover: #f2f1ed;

$border-hover: #8986f8;
$name-header: #bd8776;
$text: #4b4b4b;
$label: #2c2302;
$placeholder: rgb(204, 204, 204);
$shadow: rgba(33, 33, 33, 0.08);
$radio-hover: rgba(89, 87, 200, 0.3);

$error: #ff0000;
$error-hover: #ffb2b2;
$pending-color: #fb012e;
$alert-color: #ff9900;
$action-color: #1dbf4a;
$secondary-action-color: $white;
$status-color: #6aa84f;
$status-green-color: $action-color;
$status-yellow-color: yellow;
$status-orange-color: orange;
$status-blue-color: blue;
$status-brown-color: brown;
$status-red-color: $pending-color;
$status-gray-color: gray;

$bg-card: $primary-shadow;
$bg-card-incomplete: #fff3eb;

$font-family-getlife: "DM sans", sans-serif;
$font-family-getlife-secondary: "Merriweather", sans-serif;

$font-sm: 13px;
$font-m: 15px;
$font-lg: 20px;
$font-xl: 24px;
$font-title: 48px;
$font-secondTitle: 36px;
$font-display: 64px;

// NEW
$new-primary: #423ef4;
$new-secondary: #f5c4dc;

$font-family-getlife-primary: "DM sans", sans-serif;
$font-family-getlife-secondary: "Merriweather";
$black: #535353;

h1 {
    font-family: $font-family-getlife-secondary;
}

h2 {
    font-family: $font-family-getlife-secondary;
}
