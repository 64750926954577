.InsuranceSteps {
  background-color: white;
  overflow: hidden;
  --gapBetweenSteps: 40px;
  &-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 64px 24px;
    @media (min-width: 1280px) {
      padding: 64px 0px;
    }
  }
  &-mainTitle {
    margin: 0 auto 64px;
    color: #424242;
    font-family: "DM sans";
    font-weight: 700;
  }
  &-steps {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: var(--gapBetweenSteps);
    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }
  &-step {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 24px;
  }
  &-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    overflow: hidden;
    @media (min-width: 1024px) {
      top: 0;
      transform: translateX(calc(50% + calc(var(--gapBetweenSteps) / 2)));
    }
    img {
      filter: drop-shadow(100px 0px 0 var(--theme-primary));
      transform: translateX(-100px);
    }
    @media (min-width: 1024px) {
      img {
        filter: drop-shadow(0px 100px 0 var(--theme-primary));
        transform: translateY(-100px);
      }
    }
  }
  &-icon {
    display: block;
    width: fit-content;
    background-color: var(--theme-tertiary);
    padding: 8px;
    border-radius: 100%;
  }
  &-title {
    color: var(--theme-primary);
    font-weight: 700;
  }
  &-description {
    color: #424242;
  }
}
