@import "./../../../styles/variables.scss";

.mainbox_notFound {
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    margin: auto;
    overflow: hidden;
    padding-top: 100px;
    position: relative;
    width: 100%;
}

.title_notFound {
    color: $primary;
    font-size: 11rem;
    margin-bottom: 50px;
}

.msg_notFound {
    font-size: 1.6rem;
    text-align: center;
    p {
        margin-bottom: 50px;
    }
}

.btn_notFound {
    background: transparent;
    border-radius: 30px;
    border: 4px solid $border;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 5px 0px;
    overflow: hidden;
    padding: 8px 50px;
    position: relative;
    transition: 0.2s ease;

    &:before {
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: 0.2s ease;
        width: 0%;
        z-index: -1;
    }
    &:hover {
        background: $primary-hover;
        border: 4px solid $border-hover;
        color: $input-hover;
        transition: 0.2s ease;
        &:before {
            width: 100%;
        }
    }
}
