@import "../../../styles/variables.scss";

.infoMessage {
    padding-top: 10px;
    .infoMessage-text {
        font-size: 14px;
        color: $text;
        .infoMessage-emphasis {
            color: $primary;
        }
    }
}
