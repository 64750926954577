@import "./../../../styles/variables.scss";

.responsive-calendar {
    /* by setting font-size, all the elements will correspond */
    font-family: $font-family-getlife !important;
}

.calendar_extended_container{
    .SelectGetlife2 {
        width: 340px !important;
    }
    p{
        font-size: 12px;
        color: $primary;
        padding-top: 10px;
    }
    &__modal{
        width: min-content;
    }
}

@media (max-width: 1500px) {
    .responsive-calendar {
        font-size: 9px !important;
    }
}

@media (max-width: 1200px) {
    .responsive-calendar {
        font-size: 8px !important;
    }
}

@media (max-width: 768px) {
    .responsive-calendar {
        font-size: 7px !important;
        margin-left: 76px !important;
        margin-top: 20px;
    }

    .calendar_extended_container .SelectGetlife2 {
        width: 100% !important;
    }
}

/* Large screens */
@media (min-width: 2500px) {
    .responsive-calendar {
        font-size: 12px !important;
    }
}
