@import '../../../../styles/variables.scss';
.infoBox {
    margin-top: 30px;
    display: flex;
    align-items: center;
    background-color: #E5EFFC;
    padding: 20px 30px 16px;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    p{
        margin:0px 10px;
        font-family: $font-family-getlife;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #424242;
    }
}