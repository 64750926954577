.confirmationPage {
  $paddingX: 24px;
  width: 100%;
  max-width: calc(650px + calc($paddingX * 2));
  padding: 72px $paddingX 80px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;
  &-content {
    width: 100%;
    max-width: 544px;
    margin: 32px 0 48px;
    & > * + * {
      margin-top: 24px;
    }
  }
  &-title,
  &-email,
  &-secureEncryption {
    color: #3430e3;
  }
  &-description {
    color: #424242;
  }
  &-button {
    width: 100%;
    max-width: 352px;
  }
  &-footer {
    margin-top: 96px;
    color: #c4c4c4;
  }
  &-secureEncryption {
    margin-bottom: 64px;
  }
  &-secureIcon {
    display: inline-block;
  }
}
