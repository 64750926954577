.downloadableFiles {
    &__file{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0px;
        svg {
            margin-right: 8px;
        }
    }
}