@import "../../../../styles/variables.scss";

.skeleton {
    background-color: $primary-shadow;
    background-image: linear-gradient(90deg, $primary-shadow, $white, $primary-shadow);
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    animation: loading 1s ease-in-out infinite;
}

.skeleton.button {
    height: 48px;
    width: 157px;
}

.skeleton.iconButton {
    height: 48px;
    width: 50px;
}

.skeleton.thumbnail {
    width: 100%;
    height: 100%;
}

.skeleton.text {
    width: 100%;
    height: $font-sm;
}

.skeleton.title {
    width: 50%;
    height: $font-xl;
    margin-bottom: 15px;
}

@keyframes loading {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: calc(200px + 100%) 0;
    }
}
