@import "./../../../../styles/variables.scss";

.questions-outerContainer {
    background-color: $white;
    margin: 0 !important;
    min-height: 100%;
    min-width: 100%;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.questions-downgraded-dialog-close {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0 !important;
}

.questions-downgraded-dialog-content {
    padding: 50px !important;
    background-color: $white;
}

.questions-downgraded-dialog-content p {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    text-align: center;
}

.questions-downgraded-dialog-content span {
    font-size: 20px;
    font-weight: 700;
    color: $primary-disabled;
}

.questions-container {
    width: 700px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.questions-innerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.questions-innerContainer .control {
    max-height: unset;
    min-height: unset;
}

.questions-innerContainer .MuiFormControl-root {
    max-height: unset;
    min-height: unset;
}

@media only screen and (max-width: 768px) {
    .questions-container {
        width: calc(100% - 40px);
    }

    .questions-container input:not([type='checkbox']) {
        width: calc(100% - 40px);
    }

    .questions-container button {
        width: 100% !important;
    }

    .questions-container .location-search-input {
        width: calc(100% - 15px);
    }
}
