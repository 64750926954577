@import "./../../styles/variables.scss";

.project__container {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  padding: 40px 24px 8px 24px;
  @media (min-width: 1280px) {
    padding: 40px 80px 8px 80px;
  }
}

.projectsMain--project {
  background-color: $white;
  color: $primary;
  width: 140px;
  height: 48px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin-left: 7px;
  margin-top: 7px;
  padding: 10px;
}

.projectsMain--project span {
  font-size: 32px;
  font-weight: 300;
}
