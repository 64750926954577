.digit-group {
	input {
		width: 44px !important;
		height: 44px;
		background-color: white;
		border: 2px solid #ccc;
		line-height: 50px;
		text-align: center;
		font-size: 24px;
		font-family: 'Raleway', sans-serif;
		font-weight: 200;
		color: black;
		margin: 0 1px;
		box-sizing: border-box;
		border-radius: 8px;
		padding: 0px !important;
	}

	.splitter {
		padding: 0 5px;
		color: #ccc;
		font-size: 20px;
	}
}
