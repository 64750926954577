.LandingPage {
  scroll-behavior: smooth;
  position: relative;
  .InsuranceSteps {
    a:not(.ds-button):not(.ds-iconButton) {
      color: var(--theme-primary);
    }
  }
  a:not(.ds-button):not(.ds-iconButton) {
    color: #555555;
  }
  &-header {
    position: sticky;
    top: 0;
    z-index: 2;
    padding: 20px 24px;
    @media (min-width: 768px) {
      padding: 20px 64px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1),
      0px 3px 3px 0px rgba(0, 0, 0, 0.06), 0px 3px 4px 0px rgba(0, 0, 0, 0.07);
    &-logos {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
    &-brokerLogo {
      height: 40px;
    }
    &-brokerName {
      margin: auto 0;
      font-weight: 500;
      color: #424242;
      max-width: 100px;
    }
    &-CTA {
      display: none;
      flex-direction: row;
      gap: 8px;
      @media (min-width: 768px) {
        display: flex;
      }
    }
    &-CTA-mobile {
      display: flex;
      flex-direction: row;
      gap: 8px;
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  &-footer {
    padding: 24px 0;
    background-color: #ffffff;
    &-wrapper {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding: 24px;
      border-top: 1px solid #e5e7eb;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      @media (min-width: 1280px) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}
