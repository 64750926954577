@import './../../styles/variables.scss';

.registerEmployee--form {
    margin: 10px;
    padding: 20px;
    background-color: $white;
    width: 94.4%;
}

.registerEmployee--form__text,
.registerEmployee--form__text2 {
    font-size: 13px;
    color: $text;
    padding-bottom: 40px;
}

@media all and (min-width: 768px) {
    .registerEmployee {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 45px;
        grid-template-rows: 105px 620px;
    }

    .registerEmployee--form {
        margin: 0 30px 0 0;
        grid-column: 2;
        grid-row: 1/3;
    }

    .registerEmployee--form__text,
    .registerEmployee--form__text2 {
        padding-bottom: 30px;
    }
}

@media all and (min-width: 992px) {
    .registerEmployee {
        grid-template-rows: 105px 490px;
    }

    .img--title {
        transform: translate(5px, -13px);
    }

    .title--title {
        font-size: 28px;
    }

    .registerEmployee--form {
        width: 400px;
        height: auto;
        margin: 0;
        padding: 23px;
        display: grid;
        grid-template-columns: 170px 170px;
        grid-gap: 14px;
        grid-template-rows: 85px 105px 35px 85px 20px 35px 130px;
    }

    .registerEmployee--form__input1 {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .registerEmployee--form__input2 {
        grid-column: 2/3;
        grid-row: 1/2;
    }

    .registerEmployee--form__input3 {
        grid-column: 1/2;
        grid-row: 2/3;
    }

    .registerEmployee--form__input3 .inputGetLife-container #phone {
        margin-top: 21px;
    }

    .registerEmployee--form__text {
        grid-column: 1/3;
        grid-row: 3/4;
        padding-bottom: 0;
    }

    .registerEmployee--form__input4 {
        grid-column: 2/3;
        grid-row: 2/3;
    }

    .registerEmployee--form__input5 {
        grid-column: 1/2;
        grid-row: 4/5;
    }

    .registerEmployee--form__text2 {
        grid-column: 1/3;
        grid-row: 5/6;
    }

    .registerEmployee--form__divCheckbox {
        grid-column: 1/3;
        grid-row: 6/7;
        padding-top: 20px;
    }

    .registerEmployee--form__formbutton {
        grid-column: 1/3;
        grid-row: 7/8;
        padding-top: 70px;
    }

    .registerEmployee--form__formbutton > button {
        margin: 0;
    }
}

@media all and (min-width: 1200px) {
    .registerEmployee {
        width: 1040px;
        grid-template-rows: 105px 505px;
    }

    .registerEmployee--form {
        width: 520px;
        padding: 30px;
        grid-template-columns: 221px 221px;
        grid-gap: 18px;
        grid-template-rows: 85px 85px 35px 85px 20px 35px 130px;
    }

    .registerEmployee--form__input3 .inputGetLife-container #phone {
        margin-top: 0;
    }
}
