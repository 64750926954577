@import "./../../../../styles/variables.scss";

.insuranceApplication-mainContainer {
	background-color: $bg-web;
	display: flex;
	flex-direction: column;
	width: 100%;
}
.insuranceApplication-container {
	margin-left: 79px;
	margin-right: 79px;
	min-height: calc(100vh - 200px);
}

.insuranceApplication-headContainer {
	display: flex;
	flex-direction: column;
	padding-top: 33px;
	width: 49%;
}

.insuranceApplication-h1 {
	color: $primary;
	font-size: 32px;
	font-weight: bold;
}
.insuranceApplication-headContainer > p {
	color: $primary;
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1.5;
	padding-top: 9px;
}
.insuranceApplication-buttonContainer {
	display: flex;
	justify-content: flex-end;
	padding-top: 52px;
}
.insuranceApplication-buttonContainer > * {
	margin-left: 7px;
}

.insuranceApllication-mainHeader {
	background-color: $white;
	border-radius: 8px;
	margin-bottom: 7px;
	margin-top: 14px;
	padding: 6px 0 6px 0;
	width: 100%;
}

.insuranceApllication-mainHeader > p {
	color: $text;
	font-size: 12px;
	font-stretch: normal;
	font-style: normal;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 1.67;
	padding-left: 14px;
	text-align: left;
}
.insuranceApplication-footerContainer {
	align-content: space-around;
	align-items: center;
	background-color: $white;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	height: 80px;
	padding: 10px 20px;
	justify-content: flex-end;
	width: 100%;
	button {
		width: 100%;
		max-width: 100% !important;
	}
}
.insuranceApplication-footerMainContainer {
	border-radius: 10px;
	bottom: 10px;
	display: flex;
	margin-top: 20px;
	margin: 20px 80px;
	position: sticky;
}

@media only screen and (max-width: 804px) {
	.insuranceApplication-headContainer {
		width: 100%;
	}
}

@media only screen and (max-width: 641px) {
	.insuranceApplication-buttonContainer {
		justify-content: unset;
		flex-wrap: wrap;
	}
}

@media only screen and (max-width: 440px) {
	.insuranceApplication-headContainer {
		width: 100%;
	}
	.insuranceApplication-buttonContainer {
		flex-wrap: wrap;
	}
	.insuranceApplication-container {
		margin-left: 20px;
		margin-right: 20px;
	}
	.insuranceApplication-h1 {
		font-size: 20px;
	}
	.insuranceApplication-headContainer > p {
		color: $primary;
		font-size: 14px;
		font-stretch: normal;
		font-style: normal;
		font-weight: normal;
		letter-spacing: normal;
		line-height: 1.5;
		padding-top: 3px;
	}
}
