@import "./../../../styles/variables.scss";

.custom-header {
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 4px;
	width: 100%;
}

.custom-table__wrapper {
	margin: auto;
	padding: 50px;
	flex-direction: column;
	color: #424242;
	table {
		width: 1500px;
	}
}

.custom-table {
	border-collapse: separate;
	border-spacing: 0 5px;
	& tr {
		background: white;
		border-radius: 8px;

		& td,
		th {
			cursor: pointer;
			font-family: $font-family-getlife;
			font-size: 13px;
			color: $black;
		}
		& th {
			padding: 10px 10px;
			span {
				display: flex;
				align-items: center;
				width: max-content;

				img {
					margin-left: 10px;
				}
			}
		}
		& td {
			padding: 10px 15px;
			font-weight: 400;
		}
	}

	.custom-table__header {
		background-color: #555555;
		.custom-table__header__row {
			.custom-table__header__cell {
				background-color: #555555;
				color: #FFFFFF;
				text-align: left;
				&:first-child {
					border-radius: 8px 0 0 8px;
				}
				&:last-child {
					border-radius: 0 8px 8px 0;
				}
			}
		}
	}
	.custom-table__body {
		&:before {
			content: "";
		}
		.custom-table__body__row {
			height: 56px;
			.custom-table__body__cell {
				&.status-cell {
					font-weight: bold;
				}
				&.greeny {
					color: $status-green-color;
				}
				&.yellowy {
					color: $status-yellow-color;
				}
				&.orangy {
					color: $status-orange-color;
					font-weight: 700;
				}
				&.browny {
					color: $status-brown-color;
				}
				&.bluish {
					color: $status-blue-color;
				}
				&.reddish {
					color: $status-red-color;
				}
				&.max_capital {
					color: $status-gray-color;
				}
				&:first-child {
					border-radius: 8px 0 0 8px;
				}
				&:last-child {
					border-radius: 0 8px 8px 0;
				}
				// button {
				// 	background: #e8e8e8;
				// 	color: $black;
				// 	font-family: $font-family-getlife;
				// 	font-size: 13px;
				// 	box-shadow: none;
				// 	width: 110px;
				// }
			}
		}
		.custom-table__body_no_content {
			font-size: 16px;
		}
	}
}
