@import './../../../../styles/variables.scss'; 

.button-primary {
	align-items: center;
	background-color: $primary !important;
	border-radius: 8px;
	border: none;
	box-shadow: 0 10px 20px 0 $shadow;
	color: $white;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	font-weight: bold;
	height: 48px;
	justify-content: center;
	letter-spacing: normal;
	line-height: normal;
	min-width: 157px;
	padding: 10px;
	text-align: center;
}

.buttonPrimary-imgContainer {
	display: flex;
	align-items: center;
}

.buttonPrimary-imgContainer > img {
	padding-left: 20px;
}

.button-primary:hover {
	background-color: $primary-hover !important;
	box-shadow: none;
	transition: 0.3s;
}

.button-primary-disabled {
	align-items: center;
	background-color: $primary-disabled !important;
	border-radius: 8px;
	border: none;
	box-shadow: none;
	color: $border-hover;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-stretch: normal;
	font-style: normal;
	font-weight: bold;
	height: 48px;
	justify-content: center;
	letter-spacing: normal;
	line-height: normal;
	min-width: 200px;
	padding: 10px;
	text-align: center;
}
