@import './../../../../styles/variables.scss';

.containerModal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $text;
    font-family: $font-family-getlife-primary !important;
}

.containerModal .customModal-close {
    align-self: flex-end;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    background-color: #EDEDED;
    color: $new-primary;
    border-radius: 40px;
    height: 40px;
    width: 40px;
}

.containerModal span {
    font-weight: 700;
    color: $new-primary;
}

.containerModal .insuranceTime {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-column-gap: 15px;
    text-align: center;
    width: 400px;
}

.insuranceTime button {
    cursor: pointer;
    background-color: transparent;
    width: 140px;
    border: none;
    font-family: $font-family-getlife-primary !important;
    font-size: 18px;
    font-weight: bold;
    line-height: 120%;
    display: flex;
}

.containerModal .blockersPopUp-contentExclusion {
    font-family: $font-family-getlife-secondary;
    font-size: 24px;
    color: $new-primary;
    padding: 50px 30px 40px;
    max-width: 400px;
    font-weight: 400;
    text-align: center;
    margin: 0 auto;
}

.containerModal .blockersPopUp-contentProps {
    max-width: 400px;
    font-size: 16px;
    color: $text;
    padding: 16px 30px;
    font-weight: 400;
    text-align: center;
}

.containerModal h3 {
    font-size: 16px;
    padding-bottom: 1rem;
    width: 110px;
}

.containerModal p > span{
    font-weight: 700;
    color: $new-primary;
}

/* START LAYOUT CHART */
.containerModal .saveToday {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    grid-column-gap: 15px;
    padding-top: 0.5rem;
}

.containerModal .saveToday .yourBankChart, 
.containerModal .saveToday .getlifeChart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    max-width: 220px;
    height: 278px;
    font-weight: 700;
}

.containerModal .saveToday .getlifeChart .chartLabel {
    font-weight: 700;
    font-size: 16px;
}

.saveToday .yourBankChart > .chartLabel {
    color: black;
}

.containerModal .chartBlock {
    width: 110px;
    height: 100%;
    max-width: 165px;
    min-width: 50px;    
    max-height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 4px 4px $shadow;
}

.containerModal .chartLabel {
    font-size: 14px;
    font-weight: 400;
    max-width: 111px;
    text-align: center;
    padding-top: 10px;
    height: 58px;
}

.containerModal .chartPrice {
    font-size: 18px;
}

.containerModal .getlifeChart,
.containerModal .lifeInsuranceGetlife {
    color: $new-primary;
}

.containerModal .yourBankChart .chartBlock {
    background-color: #EDEDED;
    color: black;
}

.containerModal .getlifeChart .chartBlock {
    background-color: $new-secondary;
    height: 30%; /* height: DINAMICO CON TU BANCO; */
}

/* END LAYOUT CHART */

.containerModal .customModal-buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 50px 30px 20px;
}

.containerModal .customModal-buttonContainer .button-primary, 
.containerModal .customModal-buttonContainer .button-secondary {
    width: 100%;
    height: 60px;
    font-size: 15px;
    padding: 0px;
    font-family: $font-family-getlife-primary !important;
}

.containerModal .customModal-buttonContainer .button-secondary {
  font-weight: 100;
}

.containerModal .chartLegend {
    display: none;
}

.containerModal .subText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-bottom: 50px;
}

.containerModal .subText p {
    width: 330px;
    font-size: 14px;
}

@media (min-width: 768px) {
    .containerModal {
        padding: 20px 50px;
    }

    .containerModal h3 {
        font-size: 18px;
    }

    .containerModal .chartLabel {
        font-size: 16px;
    }


    .containerModal .saveToday .getlifeChart .chartLabel {
        font-size: 18px;
    }

    .containerModal .chartBlock {
        width: 140px;
    }

    .containerModal .chartPrice {
        font-size: 24px;
    }

    .containerModal .customModal-buttonContainer .button-primary, 
    .containerModal .customModal-buttonContainer .button-secondary {
        width: 400px;
        height: 70px;
        font-size: 24px;
    }
}

.active {
    color: $new-primary;
}

.inactive {
    color: $text;
    opacity: 0.5;
}

.first-button {
  justify-content: flex-end;
}

.second-button {
  justify-content: flex-start;
}

// TO NEW DESIGN
.containerModal .button-primary {
    background-color: $new-primary;
}

.containerModal .button-secondary {
    background-color: $white;
}
