@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root,
body,
#root,
.App {
  height: 100%;
}

.gradient-life5 {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) calc(55% - 1px),
    rgba(66, 62, 244, 1) calc(55% - 1px),
    rgba(66, 62, 244, 1) calc(55% + 1px),
    rgba(255, 255, 255, 1) calc(55% + 1px),
    rgba(255, 255, 255, 1) 100%
  );
  background-size: calc(100% - 50px) 100%;
  background-repeat: no-repeat;
  background-position: top center;
}

.tableHeaderContainerDS {
  display: flex;
  border: 1px solid var(--dark-gray-10, #ececec);
  & > div:first-child .tableHeader {
    border-top-left-radius: 8px;
  }
  & > div:last-child .tableHeader {
    border-top-right-radius: 8px;
  }
  max-width: 100%;
  .tableColumnDS:first-child {
    .tableBodyContainerDS {
      &[data-tobearchived="true"] {
        border-left: 2px solid #c2c1f7;
      }
    }
  }
  .tableColumnDS:last-child {
    .tableBodyContainerDS {
      &[data-tobearchived="true"] {
        border-right: 2px solid #c2c1f7;
      }
    }
  }
}

.tableBodyContainerDS {
  display: flex;
  align-items: center;
  height: 68px;
  align-items: center;
  color: #555555;
  gap: 4px;
  padding: 16px 12px;
  min-width: 0;
  &[data-tobearchived="true"] {
    background-color: var(--light-primary-color) !important;
  }
}

.tableProjectsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--dark-gray-10, #ececec);
  /* 1 */
  box-shadow: 0px -1px 0px 0px rgba(66, 66, 66, 0.1) inset,
    0px 1px 3px 0px rgba(66, 66, 66, 0.1);
}

.tableHeaderContainer {
  border: 1px solid var(--dark-gray-10, #ececec);
  & > div:first-child .tableHeader {
    border-top-left-radius: 8px;
  }
  & > div:last-child .tableHeader {
    border-top-right-radius: 8px;
  }
}

.tableFooterContainer {
  background-color: #ececec;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.tableHeader {
  height: 48px;
  padding: 16px 12px;
  align-items: center;
  gap: 8px;
  display: flex;
  align-items: flex-start;
  background-color: #555555;
  color: #ffffff;
}

.tableBodyRow {
  height: 68px;
  align-items: center;
  display: flex;
  align-items: center;
  color: #555555;
}

.tableBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tableBody {
  padding: 16px 12px;
  align-items: center;
  gap: 4px;
}

.rowEven {
  background-color: #ffffff;
}

.rowOdd {
  background-color: #f5f7fa;
}

.containerPage {
  width: 169px;
  margin-top: 24px;
  margin-bottom: 64px;
}

.containerText {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.PhotoIDDS-dragOver {
  background-color: #e6f3f0;
  border-color: #018565;
}
