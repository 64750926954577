@import './../../../styles/variables.scss';

.date-range__content {
	font-weight: 600;
	font-size: 12px;
	font-family: $font-family-getlife;
	display: flex;
	align-items: center;
	label {
		margin-right: 5px;
	}
	.date-range__select {
		width: 170px;
		& *,
		& *:hover {
			border: none;
		}
	}
}
